import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { dummyImages } from "utilities/dummyImages";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FlexColCenter } from "components/FlexBox/FlexBox";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";

const ImageGallery = ({ imageList, defaultImages }) => {
  const [open, setOpen] = React.useState(false);
  const [startIndex, setStartIndex] = React.useState(0);

  //Make this a global variable
  const url = "https://test.api.rentbeta.iolabz.ug";

  const handleSlider = (index) => {
    setOpen(true);
    setStartIndex(index);
  };

  return (
    <Grid>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {imageList.map((value, index) => {
          return (
            <SwiperSlide key={index}>
              {defaultImages ? (
                <img
                  style={{ cursor: "pointer" }}
                  src={value.src}
                  alt="house images"
                  onClick={() => handleSlider(index)}
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  src={`${url}${value.image}`}
                  alt="house images"
                  onClick={() => handleSlider(index)}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Grid container spacing={2}>
        {imageList.slice(0, 5).map((value, index) => {
          return (
            <Grid
              sx={{ cursor: "pointer", display: { xs: "none", sm: "block", paddingTop:30, paddingLeft: 10 } }}
              sm={2}
            >
              {defaultImages ? (
                <img
                  style={{ borderRadius: "5px" }}
                  className="swiper-slide"
                  src={value.src}
                  alt="house images"
                  onClick={() => handleSlider(index)}
                />
              ) : (
                <img
                  style={{ borderRadius: "5px" }}
                  className="swiper-slide"
                  src={`${url}${value.image}`}
                  alt="house images"
                  onClick={() => handleSlider(index)}
                />
              )}
            </Grid>
          );
        })}
        <Grid sx={{ display: { xs: "none", sm: "block", paddingTop: 30, paddingLeft: 10} }} sm={2}>
          <FlexColCenter
            sx={{
              borderRadius: "5px",
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/images/property/property4.jpg')",
              backgroundSize: "cover", // This ensures the image covers the entire FlexColCenter
              backgroundPosition: "center", // This centers the image
              cursor: "pointer",
            }}
            onClick={() => handleSlider(5)}
          >
            <Typography sx={{ color: "white" }} variant="h5">
              {dummyImages.length - dummyImages.slice(0, 5).length}
            </Typography>
            <Typography sx={{ color: "white" }} variant="h6">
              More Photos
            </Typography>
          </FlexColCenter>
        </Grid>
      </Grid>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={imageList}
        plugins={[Counter]}
        counter={{ container: { style: { top: 0, bottom: "unset" } } }}
        index={startIndex}
      />
    </Grid>
  );
};

export default ImageGallery;
