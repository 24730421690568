import React, { useState } from "react";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,IconButton,Button,Box,MenuItem,TableFooter,Menu,TablePagination,useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentsIcon from "@mui/icons-material/Payments";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom"; 
import { useSelector, useDispatch } from "react-redux";
import ViewBroker from "components/BrokerModals/ViewBroker";
import EditBroker from "components/BrokerModals/EditBroker";
import DeleteBroker from "components/BrokerModals/DeleteBroker";
import axios from "axios";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import { baseURL } from "services/API";

const BrokersTable = ({ brokers, isLoadingBrokers, error, handleMenuClick, handleMenuClose, anchorEl, handleMenuAction, newSelectedBroker }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for handling ViewBroker modal
  const [openViewBroker, setOpenViewBroker] = useState(false);
  const [openEditBroker, setOpenEditBroker] = useState(false); 
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [openDeleteBroker, setOpenDeleteBroker] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
 
  const [brokerDetails, setBrokerDetails] = useState({
    // broker_name: "",
    // brokerNin: "",
    // broker_contact: "",
    // broker_location: "",
    // email: ""
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (broker) => {
    setSelectedBroker(broker);
    setOpenViewBroker(true);
  };

  const handleCloseViewBroker = () => {
    setOpenViewBroker(false);
  };

  const handleCloseEditBroker = () => { 
    setOpenEditBroker(false);
  };

const showEditBroker = () =>{
  setSelectedBroker(brokerDetails)
  setOpenEditBroker(true)
}

  const handleEditBroker = () => {  
      setIsSubmittingData(true);
      axios.post(`${baseURL}/admin/brokers/details/${newSelectedBroker.id}`, brokerDetails)
        .then((response) => {
          console.log('Broker updated:', response.data);
          setOpenEditBroker(false);
          setIsSubmittingData(false);

        })
        .catch((error) => {
          console.error('Error updating broker:', error);
          setIsSubmittingData(false);
        });
    
  };
  const handleCloseDeleteBroker = () => {
    setOpenDeleteBroker(false); 
    
  };

  const handleDeleteBroker = () => {
    if (newSelectedBroker) {
      setIsSubmittingData(true);
      axios.delete(`${baseURL}/admin/brokers/details/${newSelectedBroker.id}`)
        .then(response => {
          console.log(response.data); 
          setOpenDeleteBroker(false); 
        })
        .catch(err => {
          console.error("Error deleting broker:", err);
        })
    } 
  };
  const handleInputChange = (event, fieldName) => {
    setBrokerDetails(broker =>({
      ...broker,
      [fieldName]:event.target.value
    })) 

  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone</StyledTableCell>
                <StyledTableCell>Location</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingBrokers ? (
                <TableRow>
                  <TableCell colSpan={5}>Loading...</TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={5}>Error fetching data</TableCell>
                </TableRow>
              ) : (
                brokers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((broker, index) => (
                  <StyledTableRow key={broker.id}>
                    <StyledTableCell>{index+1}</StyledTableCell>
                    <StyledTableCell>{broker.broker_name}</StyledTableCell>
                    <StyledTableCell>{broker.broker_contact}</StyledTableCell>
                    <StyledTableCell>{broker.broker_location}</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuClick(e, broker)}
                      >
                        <MoreVertIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => handleMenuClose(null)}
                      >
                        <MenuItem onClick={() => handleViewDetails(broker)}>
                          <VisibilityIcon color="action" sx={{ mr: 1 }} />
                          View Details
                        </MenuItem>
                        <MenuItem onClick={() => showEditBroker()}> 
                          <EditIcon color="action" sx={{ mr: 1 }} />
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuAction("Send Message")}>
                          <MessageIcon color="action" sx={{ mr: 1 }} />
                          Send Message
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuAction("Payments")}>
                          <PaymentsIcon color="action" sx={{ mr: 1 }} />
                          Payments
                        </MenuItem>
                        <MenuItem onClick={() =>  {setSelectedBroker("broker"); setOpenDeleteBroker(true);}} sx={{ color: "red" }}>
                          <DeleteIcon sx={{ mr: 1, color: "red" }} />
                          Delete
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={brokers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      {/* View Broker Modal */}
      {newSelectedBroker && (
        <ViewBroker
          open={openViewBroker}
          title="Broker Details"
          text="Here are the details of the selected broker."
          content={
            <div>
              <p>Name: {newSelectedBroker.broker_name}</p>
              <p>Phone: {newSelectedBroker.broker_contact}</p>
              <p>Email: {newSelectedBroker.broker_email}</p>
              <p>Location: {newSelectedBroker.broker_location}</p>
            </div>
          }
          handleClose={handleCloseViewBroker}
          handleSubmit={() => console.log("Submit broker details")}
          isSubmittingData={false} 
        />
      )}

      {/* Edit Broker Modal */}
      {selectedBroker && (
        <EditBroker
          open={openEditBroker}
          title="Edit Broker Details"
          text="Update the details of the broker."
          handleClose={handleCloseEditBroker}
          handleEdit={handleEditBroker}
          handleInputChange={handleInputChange}
          initialProfilePicture={newSelectedBroker.broker_picture}
          brokerName={newSelectedBroker.broker_name}
          email={newSelectedBroker.broker_email}
          phoneNumber={newSelectedBroker.broker_contact}
          location={newSelectedBroker.broker_location}

        />
        
      )}
      {selectedBroker && (
        <DeleteBroker
          open={openDeleteBroker}
          onClose={handleCloseDeleteBroker}
          onDelete={handleDeleteBroker}
          brokerName={selectedBroker?.broker_name} 
          handleDeleteClose={handleCloseDeleteBroker}
        />
      )}
    </>
  );
};

export default BrokersTable;
