import React, { useState } from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton, Menu, MenuItem, Box} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate } from 'react-router-dom'; // for navigation
import { StyledTableRow, StyledTableCell } from './Styles/TableAndCellStyles';
import { formatDate } from 'utilities/date';
import { useDispatch } from 'react-redux';
import { setTenantOccupancy } from 'state';
import { formatDateString } from 'utilities/date';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const TenantTable = ({ tenants, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {

  const navigate = useNavigate(); // use for navigation
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const handleMenuClick = (event, tenant) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenant(tenant);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTenant(null);
  };

  const handleDispatchPayments = () => {
    dispatch(setTenantOccupancy(selectedTenant))
    navigate(`/tenant-payments`);
  }

  const handleMenuAction = (action) => {
    if (selectedTenant) {
      switch (action) {
        case 'Tenant Receipt':
          navigate(`/tenant-receipts`);
          break;
        case 'Edit':
          navigate(`/tenant/${selectedTenant.id}/edit`);
          break;
        case 'Payments':
          navigate(`/tenant-payments`);
          break;
        case 'Delete':
          // Implement delete logic
          break;
        default:
          break;
      }
    }
    handleMenuClose();
  };

  return (
    <TableContainer component={Paper}>
      {<Table>
          <TableHead>
            
            <TableRow style={{backgroundColor: '#FEF0DE'}}>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Names</StyledTableCell>
              <StyledTableCell>Unit Name</StyledTableCell>
              <StyledTableCell>Date Started</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants && tenants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tenant, index) => (
                <StyledTableRow key={tenant.id}>
                  <StyledTableCell>{index+1}</StyledTableCell>
                  <StyledTableCell>{`${tenant.related_tenant.first_name} ${tenant.related_tenant.last_name}`}</StyledTableCell>
                  <StyledTableCell>{tenant.related_rental_unit.unit_name}</StyledTableCell>
                  <StyledTableCell>{formatDateString(tenant.date_added)}</StyledTableCell>
                  <StyledTableCell>{tenant.related_tenant.username}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, tenant)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleMenuAction('Edit')}>
                        <EditIcon color="action" sx={{ mr: 1 }} />
                        Edit Tenant
                      </MenuItem>
                      <MenuItem onClick={() => handleDispatchPayments(tenant)}>
                        <PaymentIcon color="action" sx={{ mr: 1 }} />
                        View Payments
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Delete')} sx={{ color: 'red' }}>
                        <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                        Delete Tenant
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tenants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};

export default TenantTable;
