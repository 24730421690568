import * as React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";  // Import the icon
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import UnitsPopover from "components/PopOvers/UnitsPopover"; // Import UnitsPopover
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useRentalUnits } from "services/hooks/rentals";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UnitsTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null); // For the popover
  const [activeUnit, setActiveUnit] = React.useState(null); // Store active unit for popover
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? "simple-popover" : undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state;
  const { rentalUnits, isLoadingRentalUnits, error } = useRentalUnits(id);

  const handleClickPopOver = (event, unit) => {
    setAnchorEl(event.currentTarget);
    setActiveUnit(unit); // Set the active unit for the popover
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, padding: "2rem" }}>
          <Typography variant="h4" gutterBottom>
            Rental Units
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Unit Name</StyledTableCell>
                  <StyledTableCell align="left">Unit Type</StyledTableCell>
                  <StyledTableCell align="left">Unit Size(m2)</StyledTableCell>
                  <StyledTableCell align="left">Rent Currency</StyledTableCell>
                  <StyledTableCell align="left">Unit Rent</StyledTableCell>
                  <StyledTableCell align="left">Unit Rent Cycle</StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingRentalUnits ? (
                  <StyledTableRow>
                    {/* Loading Spinner or Empty Row */}
                  </StyledTableRow>
                ) : (
                  <>
                    {(rowsPerPage > 0
                      ? rentalUnits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : rentalUnits
                    ).map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.related_rental_unit.unit_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {toSentenceCase(row.related_rental_unit.unit_type)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.related_rental_unit.unit_size}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.rent_currency}</StyledTableCell>
                        <StyledTableCell align="left">{row.unit_rent}</StyledTableCell>
                        <StyledTableCell align="left">
                          {toSentenceCase(row.unit_rent_cycle)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            color="primary"
                            size="small"
                            aria-describedby={popperId}
                            onClick={(event) => handleClickPopOver(event, row)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {/* Pagination can go here */}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* UnitsPopover for handling actions */}
          <UnitsPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleClosePopOver={handleClosePopOver}
            activeUnit={activeUnit}
            navigate={navigate}
          />
        </Box>
      </Box>
    </Box>
  );
}
