import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import PaymentsTable from "components/Tables/BrokerPaymentsTable";
import AddPaymentForm from "components/Forms/BrokerPaymentsForm";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import { useSelector } from "react-redux";

const BrokerPayments = () => {
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const broker = useSelector((state) => state.broker)
  const [payments, setPayments] = useState([
    { id: 1, date: "2024-01-15", amount: 1200, method: "Credit Card" },
    { id: 2, date: "2024-02-15", amount: 1300, method: "Bank Transfer" },
    { id: 3, date: "2024-03-15", amount: 1400, method: "Credit Card" },
    { id: 4, date: "2024-04-15", amount: 1500, method: "Cash" },
    { id: 5, date: "2024-05-15", amount: 1600, method: "Credit Card" },
    { id: 6, date: "2024-06-15", amount: 1700, method: "Bank Transfer" },
  ]);

  const [brokers, setBrokers] = useState([]); // Initialize as an empty array
  const [filterMonth, setFilterMonth] = useState("");
  const [newPayment, setNewPayment] = useState({
    related_broker_landlord: "",
    amount: "",
    description: "",
    date_paid: "",
    related_month: "",
    paid_by: ""
  });

  useEffect(() => {
    // Fetch the list of brokers when the component mounts
    axios.get(`${baseURL}/landlords/admin/brokers`)
      .then((response) => {
        // Ensure the response data is an array
        setBrokers(Array.isArray(response.data) ? response.data : []);
      })
      .catch((error) => {
        console.error("Error fetching brokers:", error);
      });
  }, []);

  const handleAddPaymentClick = () => {
    setShowAddPayment(true);
  };

  const handleClose = () => {
    setShowAddPayment(false);
  };

  const handleAddPayment = () => {
    setIsSubmittingData(true);
  
    // Prepare the data to be sent to the API
    const paymentData = {
      related_broker_landlord: newPayment.related_broker_landlord,
      amount: newPayment.amount,
      description: newPayment.description,
      date_paid: newPayment.date_paid,
      related_month: newPayment.related_month,
      paid_by: newPayment.paid_by,
    };
  
    // Make the API request
    axios.post(`${baseURL}/admin/broker_payments`, paymentData)
      .then((response) => {
        // Update payments state with the newly added payment
        setPayments([...payments, response.data]);
        setNewPayment({
          related_broker_landlord: "",
          amount: "",
          description: "",
          date_paid: "",
          related_month: "",
          paid_by: 1
        });
        setShowAddPayment(false);
      })
      .catch((error) => {
        console.error("Error adding payment:", error);
      })
      .finally(() => {
        setIsSubmittingData(false);
      });
  };
  

  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
  };

  // Group payments by month
  const filteredPayments = payments
    .filter((payment) => {
      const paymentDate = new Date(payment.date);
      const month = paymentDate.toLocaleString("default", { month: "long" });
      return !filterMonth || month === filterMonth;
    })
    .slice(0, 5);

  return (
    <Box m="20px">
      <Typography variant="h4" fontWeight="bold" fontSize="25px" sx={{ mb: 3 }}>
        Broker Payments
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddPaymentClick}
          sx={{ ml: "auto" }}
        >
          Add Payment
        </Button>
      </Box>

      <PaymentsTable payments={filteredPayments} broker={broker}/>

      <FormDialog
        open={showAddPayment}
        title="Add New Payment"
        text="Please fill in the details for the new payment."
        content={
          <AddPaymentForm
            newPayment={newPayment}
            setNewPayment={setNewPayment}
            brokers={brokers}  // Pass brokers data to the form
            broker={broker}
          />
        }
        handleClose={handleClose}
        handleSubmit={handleAddPayment}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default BrokerPayments;
