import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormDialog from 'components/modal'; // Ensure correct import

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AddTenantForm = ({ open, onClose, onSubmit, tenant, setTenant, isSubmittingData }) => {
  const dialogContent = (
    <>
      <FormField
        autoFocus
        margin="dense"
        id="name"
        label="Name"
        type="text"
        fullWidth
        variant="outlined"
        value={tenant.name}
        onChange={(e) => setTenant({ ...tenant, name: e.target.value })}
      />
      <FormField
        margin="dense"
        id="contact"
        label="Contact"
        type="text"
        fullWidth
        variant="outlined"
        value={tenant.contact}
        onChange={(e) => setTenant({ ...tenant, contact: e.target.value })}
      />
      <FormField
        margin="dense"
        id="amountSaved"
        label="Amount Saved"
        type="text"
        fullWidth
        variant="outlined"
        value={tenant.amountSaved}
        onChange={(e) => setTenant({ ...tenant, amountSaved: e.target.value })}
      />
      <FormField
        margin="dense"
        id="date"
        label="Date"
        type="date"
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={tenant.date}
        onChange={(e) => setTenant({ ...tenant, date: e.target.value })}
      />
      <FormField
        margin="dense"
        id="landlordName"
        label="Landlord Name"
        type="text"
        fullWidth
        variant="outlined"
        value={tenant.landlordName}
        onChange={(e) => setTenant({ ...tenant, landlordName: e.target.value })}
      />
      <FormField
        margin="dense"
        id="landlordContact"
        label="Landlord Contact"
        type="text"
        fullWidth
        variant="outlined"
        value={tenant.landlordContact}
        onChange={(e) => setTenant({ ...tenant, landlordContact: e.target.value })}
      />
      <FormField
        margin="dense"
        id="email"
        label="Email"
        type="email"
        fullWidth
        variant="outlined"
        value={tenant.email}
        onChange={(e) => setTenant({ ...tenant, email: e.target.value })}
      />
    </>
  );

  return (
    <FormDialog
      open={open}
      handleClose={onClose}
      handleSubmit={onSubmit}
      isSubmittingData={isSubmittingData}
      title="Add New Tenant"
      text="Please fill out the form below to add a new tenant."
      content={dialogContent}
    />
  );
};

export default AddTenantForm;
