import  React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InputLabel from '@mui/material/InputLabel';

export default function EditBroker({
  open,
  title,
  text,
  handleClose,
  handleSubmit,
  handleEdit,
  isSubmittingData,
  initialProfilePicture,
  userName,
  email,
  brokerName,
  brokerNin,
  phoneNumber,
  location,
  handleInputChange,
}) {
  const [profilePicture, setProfilePicture] = useState(initialProfilePicture);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 15, marginBottom: 2 }}>
            {text}
          </DialogContentText>

          {/* Profile picture and details */}
          <Box display="flex" alignItems="center" flexDirection="column" sx={{ mb: 3 }}>
            <Avatar
              alt="User Profile Picture"
              src={profilePicture}
              sx={{ width: 100, height: 100 }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{ mt: 1 }}
            >
              <input hidden accept="image/*" type="file" onChange={handleImageChange} />
              <PhotoCamera />
            </IconButton>
            <DialogContentText sx={{ fontSize: 14, marginTop: 2 }}>
              {brokerName}
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 14, color: "gray" }}>
              {email}
            </DialogContentText>
          </Box>

          {/* Form Fields */}
          <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box display="flex" gap={2}>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="broker_name" sx={{ fontSize: 14 }}>Broker Name</InputLabel>
                <TextField
                  id="broker_name"
                  variant="outlined"
                  fullWidth
                  defaultValue={brokerName}
                  onChange={(e) => handleInputChange(e, 'broker_name')}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="BrokerNin" sx={{ fontSize: 14 }}>Broker Nin</InputLabel>
                <TextField
                  id="broker_Nin"
                  variant="outlined"
                  fullWidth
                  value={brokerNin}
                  onChange={(e) => handleInputChange(e, 'brokerNin')}
                />
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="email" sx={{ fontSize: 14 }}>Email</InputLabel>
              <TextField
                id="email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => handleInputChange(e, 'email')}
              />
            </Box>
            <Box display="flex" gap={2}>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="broker_contact" sx={{ fontSize: 14 }}>Phone Number</InputLabel>
              <TextField
                id="broker_contact"
                variant="outlined"
                fullWidth
                defaultValue={phoneNumber}
                onChange={(e) => handleInputChange(e, 'broker_contact')}
                 
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="broker_location" sx={{ fontSize: 14 }}>Broker Location</InputLabel>
              <TextField
                id="broker_location"
                variant="outlined"
                fullWidth
                defaultValue={location}
                onChange={(e) => handleInputChange(e, 'broker_location')}
              />
            </Box>
            </Box>
          </Box>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="outlined" onClick={handleClose} sx={{ color: "red", fontSize: 14 }}>
            Cancel
          </Button>
          {isSubmittingData ? (
            <LoadingButton
              variant="filled"
              loading
              startIcon={<SaveIcon />}
              loadingPosition="start"
              sx={{ color: "#188c0b", fontSize: 14 }}
            >
              Editing Data ...
            </LoadingButton>
          ) : (
            <Button variant="outlined" onClick={handleEdit} sx={{ color: "#188c0b", fontSize: 14 }}>
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
