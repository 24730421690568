import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

const LoanReviewTable = ({ loans, isLoading, error, handleMenuClick, handleMenuClose, anchorEl, handleMenuAction }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: 'linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)',
                '& th': {
                  color: '#333333',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  backgroundColor: '#FEF0DE',
                },
              }}
            >
              <TableCell sx={{ fontSize: '16px' }}>Amount Requested</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Loan Purpose</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Tenant Name</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Email</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Monthly Income</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Current Rent</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Interest Rate</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Repayment Schedule</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Status</TableCell>
              <TableCell sx={{ fontSize: '16px' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={10}>Loading...</TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={10}>Error fetching data</TableCell>
              </TableRow>
            ) : (
              loans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' },
                  }}
                >
                  <TableCell sx={{ fontSize: '16px' }}>
                    ${loan.amountRequested.toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.loanPurpose}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.tenantName}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.email}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    ${loan.monthlyIncome.toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    ${loan.currentRent.toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.interestRate}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.repaymentSchedule}
                  </TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>
                    {loan.status}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, loan)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={() => handleMenuClose(null)}
                    >
                      <MenuItem onClick={() => handleMenuAction('View')}>
                        <VisibilityIcon color="action" sx={{ mr: 1 }} />
                        View
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Edit')}>
                        <EditIcon color="action" sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction('Delete')} sx={{ color: 'red' }}>
                        <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={10}
                count={loans.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LoanReviewTable;
