import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TablePagination, Box, IconButton, Grid, Typography} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useTenants } from "services/hooks/rentals";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${TableCell.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MainContent = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: theme.spacing(8), // Adjust marginTop to accommodate the Navbar height
  marginLeft: 50, // Width of the Sidebar, adjust if needed
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const TenantsPage = () => {
  const [addTenantOpen, setAddTenantOpen] = React.useState(false);
  const [newTenant, setNewTenant] = React.useState({
    name: "",
    contact: "",
    amountSaved: "",
    date: "",
    landlordName: "",
    landlordContact: "",
    email: "",
    address: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { tenants, loading, error } = useTenants();

  const handleAddTenantOpen = () => {
    setAddTenantOpen(true);
  };

  const handleAddTenantClose = () => {
    setAddTenantOpen(false);
    setNewTenant({
      name: "",
      contact: "",
      amountSaved: "",
      date: "",
      landlordName: "",
      landlordContact: "",
      email: "", // New field
      address: "", // New field
    });
  };

  const handleAddTenantSubmit = () => {
    // Implement add tenant logic
    console.log("New tenant added:", newTenant);
    handleAddTenantClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div
      style={{ display: "flex", minHeight: "100vh", flexDirection: "column" }}
    >
      <Navbar />
      <div style={{ display: "flex", flex: 2 }}>
        <Sidebar />
        <MainContent>
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <TitleContainer>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: 0,
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                >
                  Tenants
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddTenantOpen}
                >
                  Add Tenant
                </Button>
              </TitleContainer>
            </Grid>
          </Grid>

          {loading && <Typography>Loading...</Typography>}
          {error && (
            <Typography color="error">Error loading tenants.</Typography>
          )}
          {!loading && !error && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ fontSize: "18px" }}>
                      Tenant Number
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: "18px" }}>
                      First Name
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: "18px" }}>
                      Last Name
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: "18px" }}>
                      Username
                    </StyledTableCell>
                    <StyledTableCell style={{ fontSize: "18px" }}>
                      Contact
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tenants
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tenant) => (
                      <StyledTableRow
                        key={tenant.id}
                        style={{ fontSize: "18px" }}
                      >
                        <StyledTableCell style={{ fontSize: "16px" }}>
                          {tenant.tenant_number}
                        </StyledTableCell>
                        <StyledTableCell style={{ fontSize: "16px" }}>
                          {tenant.first_name}
                        </StyledTableCell>
                        <StyledTableCell style={{ fontSize: "16px" }}>
                          {tenant.last_name}
                        </StyledTableCell>
                        <StyledTableCell style={{ fontSize: "16px" }}>
                          {tenant.username}
                        </StyledTableCell>
                        <StyledTableCell style={{ fontSize: "16px" }}>
                          {tenant.contact}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tenants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableContainer>
          )}

          {/* Add Tenant Dialog */}
          <Dialog open={addTenantOpen} onClose={handleAddTenantClose}>
            <DialogTitle>Add New Tenant</DialogTitle>
            <DialogContent>
              <FormField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.name}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, name: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="contact"
                label="Contact"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.contact}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, contact: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="amountSaved"
                label="Amount Saved"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.amountSaved}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, amountSaved: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="date"
                label="Date"
                type="date"
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={newTenant.date}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, date: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="landlordName"
                label="Landlord Name"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.landlordName}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, landlordName: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="landlordContact"
                label="Landlord Contact"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.landlordContact}
                onChange={(e) =>
                  setNewTenant({
                    ...newTenant,
                    landlordContact: e.target.value,
                  })
                }
              />
              <FormField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={newTenant.email}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, email: e.target.value })
                }
              />
              <FormField
                margin="dense"
                id="address"
                label="Address"
                type="text"
                fullWidth
                variant="outlined"
                value={newTenant.address}
                onChange={(e) =>
                  setNewTenant({ ...newTenant, address: e.target.value })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddTenantClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddTenantSubmit} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </MainContent>
      </div>
    </div>
  );
};

export default TenantsPage;
