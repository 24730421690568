import React, { useState, useEffect } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import {Box,Button,Typography,Grid,styled,} from "@mui/material";
import LandlordTenantPaymentsTable from "components/Tables/LandlordTenantPaymentsTable";
import LandlordTenantPaymentsForm from "components/Forms/LandlordTenantPaymentsForm"
import { useTenants, useRentalUnits, useMonthsAndYears, useTenantPayments } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import { useSelector, useDispatch } from "react-redux";

// Style definition for the title container
const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const LandlordTenantsPayments = () => {
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newPayment, setNewPayment] = useState({
    related_rental_unit: "",
    related_tenant: "",
    amount: "",
    related_month: "",
    payment_method: "",
    date_paid: "",
  });

  const dispatch = useDispatch()

  const [amount, setAmount] = useState("")
  const [month, setMonth] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [datePaid, setDatePaid] = useState("")
  const [initialPayment, setInitialPayment] = React.useState(false);
  const [hasSecurityDeposit, setHasSecurityDeposit] = React.useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("")

  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthOptions, setMonthOptions] = useState([]);

  const landlord = useSelector((state) => state.landloard);
  const tenantOccupancy = useSelector((state) => state.tenantOccupancy);
  console.log(tenantOccupancy.id)
  const {tenantPayments, isLoadingTenantPayments, errorTenantPayments} = useTenantPayments(tenantOccupancy.id)
  const { monthsAndYears, isLoadingMonthsAndYears } = useMonthsAndYears();


	const handleChangeAmount = (event) => {
		setAmount(event.target.value);
	};

	const handleChangeMonth = (event) => {
		setMonth(event.target.value);
	};

  const handleChangeDatePaid = (event) => {
		setDatePaid(event.target.value);
	};

  const handleChangeNextPaymentDate = (event) => {
		setNextPaymentDate(event.target.value);
	};

	const handleChangePaymentMethod = (event) => {
		setPaymentMethod(event.target.value);
	};

	const handleInitialPayment = (event) => {
		setInitialPayment(event.target.checked);
	};

  const handleSecurityDeposit = (event) => {
		setHasSecurityDeposit(event.target.checked);
	};

  const rowsPerPage = 5;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (monthsAndYears) {
      setMonthOptions(
        monthsAndYears.map((monthYear) => ({
          value: monthYear.id,
          label: `${monthYear.month} ${monthYear.year}`,
        }))
      );
    }
  }, [monthsAndYears]);

  const handleSubmitTenantPayment = async () => {
		try {
      const response = await axios.post(`${baseURL}/tenants/payments/custom`, {
        related_rental_unit: tenantOccupancy.related_rental_unit.id,
        related_tenant: tenantOccupancy.related_tenant.id,
        amount: amount,
        related_month: month,
        date_paid: datePaid,
        payment_method: paymentMethod,
        is_initial_payment: initialPayment,
        has_security_deposit: hasSecurityDeposit,
        next_payment_date: nextPaymentDate,
      });
      dispatch(setRefreshCount())
			setOpenDialog(false);
			// setOpenSnack({ ...openSnack, open: true});
			// setSeverity("success");
			// setMessage("Tenant Added Successfully");
		} catch (err) {
			// setOpenSnack({ ...openSnack, open: true});
			// setSeverity("error");
			// setMessage("Tenant Addition failed, Please check data provided");
		}
	};

  return (
    <Box m="20px">
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TitleContainer>
            <Typography
              variant="h5"
              style={{ marginBottom: 0, fontWeight: "bold", fontSize: "25px", paddingLeft: 5 }}
            >
              Rent Payments for {tenantOccupancy.related_tenant.first_name} {tenantOccupancy.related_tenant.last_name}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
              Add Payment
            </Button>
          </TitleContainer>
        </Grid>
      </Grid>

      <Box>
        <LandlordTenantPaymentsTable
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          paymentData={tenantPayments} // Using dummy data
        />
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <LandlordTenantPaymentsForm 
            monthOptions = {monthsAndYears}
            isLoadingMonthsAndYears ={isLoadingMonthsAndYears} 
            handleChangeAmount={handleChangeAmount}
            handleChangeMonth={handleChangeMonth}
            month={month}
            handleChangeDatePaid={handleChangeDatePaid}
            handleChangeNextPaymentDate={handleChangeNextPaymentDate}
            handleChangePaymentMethod={handleChangePaymentMethod}
            paymentMethod={paymentMethod}
            handleInitialPayment={handleInitialPayment}
            initialPayment={initialPayment}
            handleSecurityDeposit={handleSecurityDeposit}
            hasSecurityDeposit={hasSecurityDeposit}
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitTenantPayment}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default LandlordTenantsPayments;
