import React, { useState } from "react";
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, TablePagination, TableFooter} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLandloard } from "state";
import { baseURL } from "services/API";
import EditLandlord from "components/LandlordModals/EditLandlord";
import axios from "axios";
import DeleteLandlord from "components/LandlordModals/DeleteLandlord";
import LandlordDetails from "scenes/Landlords/LandlordDetails";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 

// LandlordsTable component
const LandlordsTable = ({data,onActionClick,onEditSubmit,onSendMessage,onDeleteConfirm,onViewDetails,newSelectedLandlord,handleMenuClick}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLandlord, setSelectedLandlord] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [payDayDialogOpen, setPayDayDialogOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [payDay, setPayDay] = React.useState("");
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [openEditLandlord, setOpenEditLandlord] = useState(false);
  const [newLandlord, setNewLandlord] = useState(null)
  const dispatch = useDispatch();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("")
  const [landlordDetails, setLandlordDetails] = useState({
    user_details:{
       
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  handleMenuClick = (event, landlord) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlord(landlord);
    dispatch(setLandloard(landlord)); 
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedLandlord(null);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedLandlord(null);
  };
  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
    setAnchorEl(null)
    // handleMenuClose();
  
  }
  const handleEditLandlord = () => {
    setIsSubmittingData(true);
    axios.post(`${baseURL}/landlords/admin/landlords/details/${selectedLandlord.id}`, landlordDetails)
      .then((response) => {
        console.log('Landlord updated:', response.data);
        setOpenEditLandlord(false);
        setIsSubmittingData(false);
      })
      .catch((error) => {
        console.error('Error updating landlord:', error);
        setIsSubmittingData(false);
      });
      setEditDialogOpen(false);
      setSelectedLandlord(null);
  };

  const handleInputChange = (event, fieldName) => {
  setLandlordDetails((prevDetails) => {
    if (['first_name', 'last_name', 'username', 'email', 'phone_number'].includes(fieldName)) {
      return {
        ...prevDetails,
        user_details: {
          ...prevDetails.user_details, 
          [fieldName]: event.target.value, 
        },
      };
    } else {
      return {
        ...prevDetails, 
        [fieldName]: event.target.value, 
      };
    }
  });
};

  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedLandlord(null);
  };


  const handleMessageClick = () => {
    handleMenuClose();
    setMessageDialogOpen(true);
  };

  const handleDeleteLandlord = (landlord) => {
    setSelectedLandlord(landlord);
    setDeleteDialogOpen(true);
    setAnchorEl(null) 
    // handleMenuClose();
  };

  const handleViewClick = () => {
    handleMenuClose();
    if (selectedLandlord) {
      navigate(`/landlord-details/${selectedLandlord.id}`);
    }
  };

  const handleDelete = async () => {
    setIsSubmittingData(true);
    try {
        const response = await axios.delete(`${baseURL}/landlords/admin/landlords/details/${selectedLandlord.id}`);
        
        // Check if the response indicates a successful deletion
        if (response.status === 200) {
            setOpenSnack({
                open: true,
                message: "Landlord deleted successfully",
                severity: "success",
            });
        } else {
            setOpenSnack({
                open: true,
                message: "Failed to delete landlord",
                severity: "error",
            });
        }
    } catch (error) {
        // Handle errors from the delete request
        console.error(error);
        setOpenSnack({
            open: true,
            message: error.response?.data?.message || "Failed to delete landlord",
            severity: "error",
        });
    } finally {
        setIsSubmittingData(false);
    }
    setDeleteDialogOpen(false);
    setSelectedLandlord(null);
};


  
  const handleViewTenants = () => {
    handleMenuClose();
    if (selectedLandlord) {
      navigate(`/Landlord-tenants`);
    }
  };

  const handleSendMessage = async () => {
    if (selectedLandlord && message) {
      await onSendMessage(selectedLandlord, message);
      setMessageDialogOpen(false);
      setMessage("");
    }
  };


  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setPayDayDialogOpen(false);
    setSelectedLandlord(null);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Pay Day</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((landlord, index) => (
                <StyledTableRow key={landlord.id}>
                  <StyledTableCell>
                    {index+1}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.phone_number}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.first_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.last_name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.related_user.username}
                  </StyledTableCell>
                  <StyledTableCell>
                    {landlord.business_name}
                  </StyledTableCell>
                  <StyledTableCell>{landlord.pay_day}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, landlord)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl && selectedLandlord?.id === landlord.id
                      )}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleViewClick}>
                        <VisibilityIcon fontSize="small" />
                        View Details
                      </MenuItem>
                      <MenuItem onClick={handleEditDialogOpen}>
                        <EditIcon fontSize="small" />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleMessageClick}>
                        <MessageIcon fontSize="small" />
                        Send Message
                      </MenuItem>
                      <MenuItem onClick={handleViewTenants}>
                        <span>View Tenants</span>
                      </MenuItem>
                      <MenuItem onClick={()=>handleDeleteLandlord(landlord)}>
                        <DeleteIcon fontSize="small" />
                        Delete
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      <EditLandlord
        open={editDialogOpen}
        title="Edit Landlord Details"
        handleClose={handleEditDialogClose}
        handleSubmit={handleEditLandlord}
        isSubmittingData={false}
        handleInputChange={handleInputChange}
        profilePicture={selectedLandlord?.related_user.profile_picture}
        userName={`${selectedLandlord?.related_user.first_name} ${selectedLandlord?.related_user.last_name}`}
        email={selectedLandlord?.related_user.email}
        username={selectedLandlord?.related_user.username}
        first_name={selectedLandlord?.related_user.first_name}
        last_name={selectedLandlord?.related_user.last_name}
        phone_number={selectedLandlord?.related_user.phone_number}
        business_name={selectedLandlord?.business_name}
        pay_day={selectedLandlord?.pay_day}
        pay_percentage={selectedLandlord?.pay_percentage}
      />
      {/* Send Message Dialog */}
      <Dialog open={messageDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            type="text"
            fullWidth
            variant="standard"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleSendMessage}>Send</Button>
        </DialogActions>
      </Dialog>

{/* Delete Confirmation Dialog */}
<DeleteLandlord
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        handleDeleteClose={handleDeleteDialogClose}
        handleDelete={handleDelete} 
        selectedLandlord={selectedLandlord}
        isSubmitting={isSubmittingData} 
        landlordName={selectedLandlord ? `${selectedLandlord.related_user.first_name} ${selectedLandlord.related_user.last_name}` : ""}
      />
    </Paper>
  );
};

export default LandlordsTable;
