import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { StyledTableCell, StyledTableRow } from './Styles/TableAndCellStyles';

export default function DummyTable(props) {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
            <TableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
                <StyledTableCell align="left"><Skeleton></Skeleton></StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}