import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, Box} from "@mui/material";
import BookingTable from "components/Tables/BookingTable";

const Booking = () => {
  const [addTenantOpen, setAddTenantOpen] = React.useState(false);
  const [newTenant, setNewTenant] = React.useState({
    name: "",
    contact: "",
    email: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tenants, setTenants] = React.useState([
    { id: 1, tenant_number: "T001", first_name: "John", last_name: "Doe", username: "john.doe@example.com", contact: "1234567890" },
    { id: 2, tenant_number: "T002", first_name: "Jane", last_name: "Doe", username: "jane.doe@example.com", contact: "0987654321" },
  ]);

  const handleAddTenantOpen = () => {
    setAddTenantOpen(true);
  };

  const handleAddTenantClose = () => {
    setAddTenantOpen(false);
    setNewTenant({
      name: "",
      contact: "",
      email: "",
    });
  };

  const handleAddTenantSubmit = () => {
  }
      

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="20px">
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <div style={{ marginBottom: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h4" component="h2" fontWeight="bold" fontSize="25px">Booking</Typography>
          </div>
          <BookingTable
            tenants={tenants}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Booking;
