import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ManagersTable from "components/Tables/ManagerTable";
import { setManager } from "state";
import { useManagers } from "services/hooks/rentals";
import FormDialog from "components/modal";
import ManagerForm from "components/Forms/ManagerForm";
import { useState } from "react";

export default function Managers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [open, setOpen] = React.useState(false);
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const itemsPerPage = 10;

  const [newManager, setNewManager] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    username: "",
    password: "",
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedManager, setSelectedManager] = React.useState(null);
  const [errors, setErrors] = React.useState({});

  // Snack bar state
  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage,setErrorMessage] = useState("")
  const [openSnack, setOpenSnack] = React.useState(false);

  const { managers, isLoadingManager, error, refetch } = useManagers();

  const handleMenuClick = (event, manager) => {
    setAnchorEl(event.currentTarget);
    setSelectedManager(manager);
  };

  const handleMenuClose = (action) => {
    setAnchorEl(null);
    if (action === "Edit") {
      setEditDialogOpen(true);
    } else if (action === "Send Message") {
      setMessageDialogOpen(true);
    } else if (action === "Delete") {
      setDeleteDialogOpen(true);
    } else if (action === "View") {
      if (selectedManager) {
        dispatch(setManager(selectedManager));
        navigate(`/manager-details/${selectedManager.id}`);
      }
    } else if (action === "Receipt") {
      if (selectedManager) {
        navigate(`/tenant-receipts`);
      }
    }
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setDeleteDialogOpen(false);
    setOpen(false);
    setNewManager({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      username: "",
      password: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewManager((prevManager) => ({ ...prevManager, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};

    // Validate first name and last name
    if (!newManager.first_name.trim()) errors.first_name = "First Name is required.";
    if (!newManager.last_name.trim()) errors.last_name = "Last Name is required.";

    // Validate email format and uniqueness
    if (!newManager.email) {
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newManager.email)) {
      errors.email = "Email is invalid.";
    }

    // Validate phone number format
    if (!newManager.phone_number) {
      errors.phone_number = "Phone Number is required.";
    } else if (!/^(078|25678|07[0-9]{8}|256[0-9]{9})$/.test(newManager.phone_number)) {
      errors.phone_number = "Phone Number is invalid. Format: 0781066548 or +256781066548.";
    }

    // Validate alternative contact if provided
    if (newManager.alternative_contact && !/^(078|25678|07[0-9]{8}|256[0-9]{9})$/.test(newManager.alternative_contact)) {
      errors.alternative_contact = "Alternative contact is invalid. Format: 0781066548 or +256781066548.";
    }

    // Validate username
    if (!newManager.username.trim()) errors.username = "Username is required.";

    // Validate password strength
    if (!newManager.password) {
      errors.password = "Password is required.";
    } else if (!/(?=.*[a-z]){6,}/.test(newManager.password)) {
      errors.password = "Password must be at least 8 characters and include uppercase, lowercase, number, and special character.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmitManager = async () => {
    if (!validateForm()) {
      setOpenSnack({
          open: true,
          message: "Please fill all fields",
          severity: "error",
      });
      return;
  }
    setIsSubmittingData(true);
    try {
      const response = await axios.post(`${baseURL}/admin/managers`, newManager);
      const { data } = response;
      setSuccessMessage(`Manager ${newManager.first_name} ${newManager.last_name} added successfully`)

    } catch (error) {
      console.error(error);
      setErrorMessage(error.response?.data?.error)
    } finally {
      setIsSubmittingData(false);
      handleDialogClose();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  if (errorMessage) {
    setTimeout(() => {
      setErrorMessage(""); 
    }, 5000);
  }

  return (
    <Box m="20px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Box
        sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
            <Typography fontWeight="bold" fontSize="25px">
              Managers
            </Typography>

            {
               successMessage && 
              <p sx={{color: "green", fontSize: "14px"}}>{successMessage}</p>
            }

        {errorMessage && (
          <p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>
        )}
            <Button variant="contained" onClick={() => setOpen(true)} sx={{ marginLeft: 'auto' }} >
              Add Manager
            </Button>
          </Box>

          <FormDialog
            open={open}
            title="Add New Manager"
            text="Please fill in the details below to add a new manager."
            content={
              <ManagerForm
                handleChange={handleChange}
                newManager={newManager}
                errors={errors}
              />
            }
            handleClose={handleDialogClose}
            handleSubmit={handleSubmitManager}
            isSubmittingData={isSubmittingData}
          />

          <ManagersTable
            managers={managers}
            isLoadingManagers={isLoadingManager}
            error={error}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            anchorEl={anchorEl}
            newSelectedManager={selectedManager}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
