import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  posts: [],
  refreshCount: 0,
  broker: {},
  manager: {},
  landloard: {},
  isAdmin: 0,
  managerDetails: {},
  tenantOccupancy: {},
  tenantPayment: {}
};

export const manager_id = 1;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAdmin = action.payload.admin
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.isAdmin = null;
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    setRefreshCount: (state, action) => {
      state.refreshCount = action.payload; // Added reducer for refresh count
    },
    setBroker: (state, action) => {
      state.broker = action.payload;
    },
    setManager: (state, action) => {
      state.manager = action.payload;
    },
    setLandloard: (state, action) => {
      state.landloard = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setManagerDetails: (state, action) => {
      state.managerDetails = action.payload;
    },
    setTenantOccupancy: (state, action) => {
      state.tenantOccupancy = action.payload;
    },
    setTenantPayment: (state, action) => {
      state.tenantPayment = action.payload;
    },
  },
});

export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, setRefreshCount, setBroker, setManager, setLandloard, setIsAdmin, setManagerDetails, setTenantOccupancy, setTenantPayment } = authSlice.actions;
export default authSlice.reducer;
