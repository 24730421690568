import React, { useState } from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, useTheme, IconButton, Menu, MenuItem} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 
import Chip from "@mui/material/Chip";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import { useNavigate } from 'react-router-dom'; // for navigation
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from "react-redux";
import { setTenantPayment } from "state";
import { formatDateString } from "utilities/date";

const LandlordTenantPaymentsTable = ({ paymentData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleMenuClick = (event, tenant) => {
    setAnchorEl(event.currentTarget);
    // setSelectedTenant(tenant);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedTenant(null);
  };

  const renderStatusPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="NO" size="small" color="warning" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="YES" size="small" color="primary" />;
		}
	};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowReceipt = (payment) => {
    dispatch(setTenantPayment(payment))
    navigate(`/tenant-receipts`);
  }

  return (
    <>
      {paymentData.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell >#</StyledTableCell>
                  <StyledTableCell >Amount Paid</StyledTableCell>
                  <StyledTableCell >Partial Payments</StyledTableCell>
                  <StyledTableCell>Has Security Payment</StyledTableCell>
                  <StyledTableCell>Month Paid For</StyledTableCell>
                  <StyledTableCell>Date Started</StyledTableCell>
                  <StyledTableCell>Date Ending</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? paymentData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : paymentData
                ).map((payment, index) => (
                  <StyledTableRow
                    key={payment.id}
                  >
                    <StyledTableCell>{index+1}</StyledTableCell>
                    <StyledTableCell>{payment.total}</StyledTableCell>
                    <StyledTableCell>{payment.count}
                    </StyledTableCell>
                    <StyledTableCell>{renderStatusPills(payment.has_security_deposit)}</StyledTableCell>
                    <StyledTableCell>{`${payment.related_month?.month} ${payment.related_month?.year}`}</StyledTableCell>
                    <StyledTableCell>{formatDateString(payment.date_started)}</StyledTableCell>
                    <StyledTableCell>{formatDateString(payment.date_ended)}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuClick(e, payment)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={()=>{}}>
                          <EditIcon color="action" sx={{ mr: 1 }} />
                          Edit Payment
                        </MenuItem>
                        <MenuItem onClick={()=> handleShowReceipt(payment)}>
                          <PaymentIcon color="action" sx={{ mr: 1 }} />
                          View Receipt
                        </MenuItem>
                        <MenuItem onClick={()=>{}} sx={{ color: 'red' }}>
                          <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                          Delete Payment
                        </MenuItem>
                      </Menu>
                  </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={paymentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments available</Typography>
      )}
    </>
  );
};

export default LandlordTenantPaymentsTable;
