import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TablePagination, Box, IconButton, Grid, Typography} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useUsers } from "services/hooks/rentals";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: "#FEF0DE",
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: "bold",
  },
  [`&.${TableCell.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const MainContent = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: theme.spacing(8), // Adjust marginTop to accommodate the Navbar height
  marginLeft: 50, // Width of the Sidebar, adjust if needed
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const TenantsPage = () => {
  const [addTenantOpen, setAddTenantOpen] = React.useState(false);
  const [newTenant, setNewTenant] = React.useState({
    name: "",
    contact: "",
    amountSaved: "",
    date: "",
    landlordName: "",
    landlordContact: "",
    email: "",
    address: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { users, loading, error } = useUsers();

  const handleAddTenantOpen = () => {
    setAddTenantOpen(true);
  };

  const handleAddTenantClose = () => {
    setAddTenantOpen(false);
    setNewTenant({
      name: "",
      contact: "",
      amountSaved: "",
      date: "",
      landlordName: "",
      landlordContact: "",
      email: "",
      address: "",
    });
  };

  const handleAddTenantSubmit = () => {
    // Implement add tenant logic
    console.log("New tenant added:", newTenant);
    handleAddTenantClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box m="20px">
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <TitleContainer>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 0,
                  fontWeight: "bold",
                  fontSize: "25px",
                }}
              >
                Users
              </Typography>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleAddTenantOpen}
              >
                Add Tenant
              </Button> */}
            </TitleContainer>
          </Grid>
        </Grid>

        {loading && <Typography>Loading...</Typography>}
        {error && (
          <Typography color="error">Error loading tenants.</Typography>
        )}
        {!loading && !error && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ fontSize: "18px" }}>
                    Tenant Number
                  </StyledTableCell>
                  <StyledTableCell style={{ fontSize: "18px" }}>
                    First Name
                  </StyledTableCell>
                  <StyledTableCell style={{ fontSize: "18px" }}>
                    Last Name
                  </StyledTableCell>
                  <StyledTableCell style={{ fontSize: "18px" }}>
                    Username
                  </StyledTableCell>
                  <StyledTableCell style={{ fontSize: "18px" }}>
                    Contact
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <StyledTableRow
                      key={user.id}
                      style={{ fontSize: "18px" }}
                    >
                      <StyledTableCell style={{ fontSize: "16px" }}>
                        {user.tenant_number}
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: "16px" }}>
                        {user.first_name}
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: "16px" }}>
                        {user.last_name}
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: "16px" }}>
                        {user.username}
                      </StyledTableCell>
                      <StyledTableCell style={{ fontSize: "16px" }}>
                        {user.contact}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>
        )}

        <Dialog open={addTenantOpen} onClose={handleAddTenantClose}>
          <DialogTitle>Add Tenant</DialogTitle>
          <DialogContent>
            <FormField
              label="Name"
              fullWidth
              value={newTenant.name}
              onChange={(e) =>
                setNewTenant({ ...newTenant, name: e.target.value })
              }
            />
            <FormField
              label="Contact"
              fullWidth
              value={newTenant.contact}
              onChange={(e) =>
                setNewTenant({ ...newTenant, contact: e.target.value })
              }
            />
            <FormField
              label="Amount Saved"
              fullWidth
              value={newTenant.amountSaved}
              onChange={(e) =>
                setNewTenant({ ...newTenant, amountSaved: e.target.value })
              }
            />
            <FormField
              label="Date"
              fullWidth
              type="date"
              value={newTenant.date}
              onChange={(e) =>
                setNewTenant({ ...newTenant, date: e.target.value })
              }
            />
            <FormField
              label="Landlord Name"
              fullWidth
              value={newTenant.landlordName}
              onChange={(e) =>
                setNewTenant({ ...newTenant, landlordName: e.target.value })
              }
            />
            <FormField
              label="Landlord Contact"
              fullWidth
              value={newTenant.landlordContact}
              onChange={(e) =>
                setNewTenant({
                  ...newTenant,
                  landlordContact: e.target.value,
                })
              }
            />
            <FormField
              label="Email"
              fullWidth
              value={newTenant.email}
              onChange={(e) =>
                setNewTenant({ ...newTenant, email: e.target.value })
              }
            />
            <FormField
              label="Address"
              fullWidth
              value={newTenant.address}
              onChange={(e) =>
                setNewTenant({ ...newTenant, address: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddTenantClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddTenantSubmit} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
    </Box>
  );
};

export default TenantsPage;
