import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteBroker({
  open,
  handleDeleteClose,
  onDelete,
  isSubmittingData,
  managerName,
}) {
  return (
    <Dialog open={open} onClose={handleDeleteClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: 20, color: "red" }}>Delete Broker</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 15, marginBottom: 2 }}>
          Are you sure you want to delete the broker <strong>{managerName}</strong>? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isSubmittingData ? (
          <LoadingButton
            variant="outlined"
            loading
            startIcon={<DeleteIcon />}
            loadingPosition="start"
            sx={{ color: "#d32f2f", fontSize: 14 }}
          >
            Deleting...
          </LoadingButton>
        ) : (
          <>
            <Button variant="outlined" onClick={handleDeleteClose} sx={{ color: "red", fontSize: 14 }}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={onDelete} sx={{ color: "#d32f2f", fontSize: 14 }}>
              Delete
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
