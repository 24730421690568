import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Navbar from "scenes/navbar";
import Sidebar from "scenes/global/Sidebar";
import TopSnack from "components/TopSnack";
import LoanRequestsTable from "components/Tables/LoanRequestsTable";

// Sample data for loan requests
const loanRequestsData = [
  { Contact:"0764445678", tenantName: "John Doe", amount: 1500, dateSubmitted: "2023-08-21", status: "Pending" },
  { Contact:"0768445678", tenantName: "Jane Smith", amount: 2000, dateSubmitted: "2023-09-01", status: "Pending" },
  { iContact:"0763345678", tenantName: "Bob Johnson", amount: 1000, dateSubmitted: "2023-09-05", status: "Reviewed" },
  // Add more records as needed
];

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [openSnack, setOpenSnack] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const navigate = useNavigate(); // Hook to navigate between routes

  useEffect(() => {
    // Replace this with a call to the server to fetch loan requests
    setLoanRequests(loanRequestsData);
  }, []);

  const handleReviewLoan = (id) => {
    navigate(`/loan-review`); // Navigate to the loan-review page with the loan ID
  };

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
          >
            Loan Requests
          </Typography>

          {/* Use LoanRequestsTable component */}
          <LoanRequestsTable loanRequests={loanRequests} handleReviewLoan={handleReviewLoan} />
        </Grid>
      </Grid>

      {/* Snackbar to show notifications */}
      <TopSnack openSnack={openSnack} setOpenSnack={setOpenSnack} />
    </Box>
  );
};

export default LoanRequests;
