import React, { useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useRentals, useMonthsAndYears, useManagers } from "services/hooks/rentals";

const AddPaymentForm = ({
  newPayment,
  handleInputChange,
  handleAddPayment,
  handleCloseModal,
}) => {
  const landlord = useSelector((state) => state.landlord); // Select the landlord stored in the global state
  const { rentals, isLoadingRentals, errorRentals } = useRentals(landlord.id);
  const { monthsAndYears, isLoadingMonthsAndYears, errorMonthsAndYears } = useMonthsAndYears();
  const { managers, isLoadingManagers, error: errorManagers } = useManagers();

  useEffect(() => {
    // If you need to perform side effects based on monthsAndYears, do it here
  }, [monthsAndYears]);

  const handleInputChangeInternal = (event) => {
    const { name, value } = event.target;

    // Handle related_month separately by setting it as the id (primary key)
    if (name === "related_month") {
      handleInputChange({
        target: {
          name: "related_month",
          value: value, // Value is now the ID of the month-year object
        },
      });
    } else {
      handleInputChange(event); // Handle other inputs normally
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Landlord Rental</InputLabel>
        <Select
          name="related_landlord_rental"
          value={newPayment.related_landlord_rental || ""}
          onChange={handleInputChange}
          label="Landlord Rental"
        >
          {isLoadingRentals ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorRentals ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load rentals</Typography>
            </MenuItem>
          ) : rentals.length === 0 ? (
            <MenuItem disabled>
              <Typography>No rentals available</Typography>
            </MenuItem>
          ) : (
            rentals.map((rental) => (
              <MenuItem key={rental.id} value={rental.id}>
                {rental.rental_name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <TextField
        label="Amount"
        type="number"
        name="amount"
        value={newPayment.amount}
        onChange={handleInputChange}
        variant="outlined"
      />
      <TextField
        label="Description"
        name="description"
        value={newPayment.description}
        onChange={handleInputChange}
        variant="outlined"
      />
      <TextField
        label="Date Paid"
        type="date"
        name="date_paid"
        value={newPayment.date_paid}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        variant="outlined"
      />

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Related Month (Month and Year)</InputLabel>
        <Select
          name="related_month"
          value={newPayment.related_month || ""}
          onChange={handleInputChangeInternal}
          label="Related Month (Month and Year)"
        >
          {isLoadingMonthsAndYears ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorMonthsAndYears ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load months and years</Typography>
            </MenuItem>
          ) : monthsAndYears.length === 0 ? (
            <MenuItem disabled>
              <Typography>No months and years available</Typography>
            </MenuItem>
          ) : (
            monthsAndYears.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {`${item.month} ${item.year}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Paid By (Manager)</InputLabel>
        <Select
          name="paid_by"
          value={newPayment.paid_by}
          onChange={handleInputChange}
          label="Paid By (Manager)"
        >
          {isLoadingManagers ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : errorManagers ? (
            <MenuItem disabled>
              <Typography color="error">Failed to load managers</Typography>
            </MenuItem>
          ) : managers.length === 0 ? (
            <MenuItem disabled>
              <Typography>No managers available</Typography>
            </MenuItem>
          ) : (
            managers.map((manager) => (
              <MenuItem key={manager.id} value={manager.related_user.id}>
                {`${manager.related_user.first_name} ${manager.related_user.last_name}`}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default AddPaymentForm;
