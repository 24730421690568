import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FormDialog from "components/modal";
import EditDialog from "components/ManagerModals/EditModal";
import DeleteDialog from "components/ManagerModals/DeleteManager";

// Import the styled components
import { StyledTableCell, StyledTableRow } from 'components/Tables/Styles/TableAndCellStyles';
import axios from "axios";
import { baseURL } from "services/API";

export default function ManagersTable({
  managers = [],
  isLoadingManagers,
  error,
  handleMenuClick,
  newSelectedManager,
  handleMenuClose,
  anchorEl,
  currentPage,
  itemsPerPage,
  handlePageChange
}) {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete dialog
  const [selectedManager, setSelectedManager] = useState(null);
  const [isSubmittingData, setIsSubmittingData] = useState(false); // State for loading status

  const handleViewManager = (manager) => {
    setSelectedManager(manager);
    setDialogOpen(true);
    handleMenuClose();
  };

  const handleEditManager = (manager) => {
    setSelectedManager(manager);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleDeleteManager = (manager) => {
    setSelectedManager(manager);
    setDeleteDialogOpen(true); 
    handleMenuClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedManager(null);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedManager(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setSelectedManager(null);
  };

  const handleDelete = async () => {
    
    setIsSubmittingData(true); 
    const response = await axios.delete(`${baseURL}/admin/managers/details/${newSelectedManager.id}`)
    console.log(response.data)
    setIsSubmittingData(false);
    handleDeleteDialogClose();
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ fontSize: "18px" }}>First Name</StyledTableCell>
                <StyledTableCell style={{ fontSize: "18px" }}>Last Name</StyledTableCell>
                <StyledTableCell style={{ fontSize: "18px" }}>Email</StyledTableCell>
                <StyledTableCell style={{ fontSize: "18px" }}>Phone Number</StyledTableCell>
                <StyledTableCell style={{ fontSize: "18px" }}>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managers.length === 0 ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>No data available</StyledTableCell>
                </StyledTableRow>
              ) : (
                managers.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage).map((manager, index) => (
                  <StyledTableRow key={manager.id} style={{ backgroundColor: index === 0 ? '#f5f5f5' : 'inherit' }}>
                    <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.first_name}</StyledTableCell>
                    <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.last_name}</StyledTableCell>
                    <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.email}</StyledTableCell>
                    <StyledTableCell style={{ fontSize: "16px" }}>{manager.related_user.phone_number}</StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={(e) => handleMenuClick(e, manager)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={() => handleViewManager(manager)}>
                          <VisibilityIcon />
                          View
                        </MenuItem>
                        <MenuItem onClick={() => handleEditManager(manager)}>
                          <EditIcon />
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuClose("Send Message")}>
                          <MessageIcon />
                          Send Message
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteManager(manager)}> {/* Change to call the delete handler */}
                          <DeleteIcon />
                          Delete
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  count={managers.length}
                  rowsPerPage={itemsPerPage}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={(e) => handlePageChange(e, 0)}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>

      {/* Dialog to display manager details */}
      <FormDialog
        open={dialogOpen}
        title="Manager Details"
        text="Here are the details of the selected manager:"
        content={
          newSelectedManager && (
            <Paper elevation={3} sx={{ padding: 3, backgroundColor: "#f9f9f9" }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                  Manager Information
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <AccountCircleIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography variant="body1">
                  <strong>First Name:</strong> {newSelectedManager.related_user.first_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <AccountCircleIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography variant="body1">
                  <strong>Last Name:</strong> {newSelectedManager.related_user.last_name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <EmailIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography variant="body1">
                  <strong>Email:</strong> {newSelectedManager.related_user.email}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <PhoneIcon sx={{ color: "primary.main", mr: 1 }} />
                <Typography variant="body1">
                  <strong>Phone Number:</strong> {newSelectedManager.related_user.phone_number}
                </Typography>
              </Box>
            </Paper>
          )
        }
        handleClose={handleDialogClose}
      />

      {/* Edit Dialog */}
      <EditDialog
        open={editDialogOpen}
        title="Edit Manager Details"
        handleClose={handleEditDialogClose}
        handleSubmit={handleEditManager}
        isSubmittingData={false}
        profilePicture={selectedManager?.related_user.profile_picture}
        userName={`${newSelectedManager?.related_user.first_name} ${newSelectedManager?.related_user.last_name}`}
        email={newSelectedManager?.related_user.email}
        firstName={newSelectedManager?.related_user.first_name}
        lastName={newSelectedManager?.related_user.last_name}
        phoneNumber={newSelectedManager?.related_user.phone_number}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        handleDeleteClose={handleDeleteDialogClose}
        handleDelete={handleDelete} // Confirm action
        newSelectedManager={newSelectedManager}
        isSubmitting={isSubmittingData} // To indicate loading state
        managerName={selectedManager ? `${selectedManager.related_user.first_name} ${selectedManager.related_user.last_name}` : ""}
      />
    </>
  );
}
