import * as React from "react";
import axios from "axios";
import {Box, Button, Typography, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import LandlordsTable from "components/Tables/LandlordsTable";
import TopSnack from "components/TopSnack";
import { useLandlords } from "services/hooks/rentals";
import { baseURL } from "services/API";
import FormDialog from "components/modal"; 
import AddLandlordForm from "components/Forms/LandlordForm"; 
import { useState } from "react";

const Landlords = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [filterType, setFilterType] = React.useState("");
  const [activeLandlord, setActiveLandlord] = React.useState("");

  const [selectedLandlord, setSelectedLandlord] = React.useState(null);
//SET THE MESSAGE AND ERROR FOM THE SERVE HERE
const [successMessage, setSuccessMessage] = useState("")
const [errorMessage,setErrorMessage] = useState("")

  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [newLandlord, setNewLandlord] = React.useState({
    phone_number: "",
    first_name: "",
    username: "",
    last_name: "",
    password: "",
    email: "",
    business_name: "",
  });

  const [validationErrors, setValidationErrors] = React.useState({});
  const [isSubmittingData, setIsSubmittingData] = React.useState(false);

  const { landlords, isLoadingLandlords, error } = useLandlords();

  const handleMenuClick = (event, landlord) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlord(landlord);
  };

  

  const handleDialogClose = () => setOpenDialog(false);

  // Validation function
const validateForm = () => {
  const errors = {};
  
  // Check if required fields are filled
  if (!newLandlord.first_name) {
      errors.first_name = "First name is required";
  }
  if (!newLandlord.last_name) {
      errors.last_name = "Last name is required";
  }
  if (!newLandlord.username) {
      errors.username = "Username is required";
  }
  if (!newLandlord.email) {
      errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(newLandlord.email)) {
      errors.email = "Email is invalid";
  }
  if (!newLandlord.phone_number) {
    errors.phone_number = "Phone number is required";
} else if (!/^(0\d{9}|\+\d{1,3}\d{9})$/.test(newLandlord.phone_number)) {
    errors.phone_number = "Phone number must be in the format 076337019 or +25676337019";
}
  if (!newLandlord.password) {
      errors.password = "Password is required";
  } else if (newLandlord.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
  }
  if (!newLandlord.business_name) {
      errors.business_name = "Business name is required";
  }

  setValidationErrors(errors);
  return Object.keys(errors).length === 0; 
};


  const handleAddLandlordSubmit = async () => {
    if (!validateForm()) {
        setOpenSnack({
            open: true,
            message: "Please fill all fields",
            severity: "error",
        });
        return;
    }

    setIsSubmittingData(true);
    try {
      setSuccessMessage("")
      setErrorMessage("")
        await axios.post(`${baseURL}/accounts/users/landlords`, newLandlord);
        
        setSuccessMessage("Landlord added successfully")
        setNewLandlord({
            phone_number: "",
            first_name: "",
            username: "",
            last_name: "",
            password: "",
            email: "",
            business_name: "",
        });
        setValidationErrors({});
        handleDialogClose();
    } catch (error) {
        console.log(error.response?.data?.error); 
        setErrorMessage(error.response?.data?.error)
        
    } finally {
        setIsSubmittingData(false);
    }
};

  

  const handleFilterTypeChange = (event) => setFilterType(event.target.value);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewLandlord((prev) => ({ ...prev, [name]: value }));
  };

  if (successMessage) {
    setTimeout(() => {
      setSuccessMessage(""); 
    }, 2000);
  }
  return (
    <Box m="20px">
      <Box container spacing={2}>
        <Box item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
          >
            Landlords
          </Typography>

{
  successMessage && 
<p sx={{color: "green", fontSize: "14px"}}>{successMessage}</p>
}

          <Box
            sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ flexGrow: 1 }}
            >
              <InputLabel>Filter By</InputLabel>
              <Select
                value={filterType}
                onChange={handleFilterTypeChange}
                label="Filter By"
                sx={{ width: 300, fontSize: 14 }}
              >
                <MenuItem value="landlordNumber">Landlord Number</MenuItem>
                <MenuItem value="landlordName">Landlord Name</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Add Landlord
            </Button>
          </Box>
          <LandlordsTable
            data={landlords}
            handleMenuClick={handleMenuClick}
            loading={isLoadingLandlords}
            error={error}
            newSelectedLandlord={selectedLandlord}
          />
        </Box>
      </Box>

      <TopSnack openSnack={openSnack} setOpenSnack={setOpenSnack} />

      {/* Form Dialog */}
      <FormDialog
        open={openDialog}
        title="Add Landlord"
        text="Please fill in the details of the new landlord."
        content={
          <AddLandlordForm
            newLandlord={newLandlord}
            handleChange={handleChange}
            validationErrors={validationErrors}
          />
        }
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleClose={handleDialogClose}
        handleSubmit={handleAddLandlordSubmit}
        isSubmittingData={isSubmittingData}
      />
    </Box>
  );
};

export default Landlords;
