import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Typography, Paper, Menu, MenuItem, Stack, Snackbar, Alert} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import ManagerDetailsTable from "components/Tables/managerDetailsTable";
import FormDialog from "components/modal";
import { ManagerDetailsForm } from "components/Forms/managerDetailForm";
import { useAttachedProperty, useUnAssignedProperties } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import { baseURL } from "services/API";

export default function ManagerDetails() {
  const manager = useSelector((state) => state.manager);
  const [newProperty, setNewProperty] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [isSubmittingData, setSubmittingData] = useState(false);

  // Fetch attached properties
  const { attachedProperties, isLoadingAttachedProperties, errorAttachedProperties } = useAttachedProperty(manager.id);

  // Fetch unassigned properties
  const { unAssignedProperties, isLoadingUnAssignedProperties, errorUnAssignedProperties } = useUnAssignedProperties();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setNewProperty(event.target.value);
  };

  const handleDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleOpenAttachProperty = () => {
    setOpen(true);
  };

  const handleAttachProperty = async () => {
    console.log(newProperty);
    setSubmittingData(true);
    try {
      await axios.post(`${baseURL}/admin/managers/properties`, {
        related_property: newProperty,
        date_started: startDate,
        related_manager: manager.id
      });

      setSubmittingData(false);
      setOpen(false);
      setSnack({
        open: true,
        message: "Property attached successfully",
        severity: "success",
      });
    } catch (error) {
      setSnack({
        open: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
      setSubmittingData(false);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setNewProperty(null);
  };

  const handleEditLandlord = () => {
    console.log("Editing property:", newProperty);
    handleMenuClose();
  };

  const handleDeleteLandlord = async () => {
    // Implement landlord deletion (if needed)
    handleMenuClose();
  };

  const handleSendMessage = () => {
    handleMenuClose();
  };

  const handleCloseSnack = () => {
    setSnack({ ...snack, open: false });
  };

  return (
    <Box m="20px">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
        >
          Manager Details
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAttachProperty}
        >
          Attach New Property
        </Button>
      </Stack>
      <Paper sx={{ marginTop: "1rem", overflow: "hidden" }}>
        <ManagerDetailsTable
          handleMenuOpen={handleMenuOpen}
          attachedProperties={attachedProperties}
          isLoadingAttachedProperties={isLoadingAttachedProperties}
          errorAttachedProperties={errorAttachedProperties}
        />
      </Paper>

      <FormDialog
        open={open}
        title={"Attach Property"}
        text={"Assign a property to a manager"}
        content={
          <ManagerDetailsForm
            newProperty={newProperty}
            handleChange={handleChange}
            unassignedProperties={unAssignedProperties}
            isLoadingProperties={isLoadingUnAssignedProperties}
            startDate={startDate}
            handleDateChange={handleDateChange}
          />
        }
        handleClose={handleClose}
        handleSubmit={handleAttachProperty}
        isSubmittingData={isSubmittingData}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditLandlord}>
          <EditIcon sx={{ marginRight: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleSendMessage}>
          <MailIcon sx={{ marginRight: 1 }} />
          Send Message
        </MenuItem>
        <MenuItem onClick={handleDeleteLandlord}>
          <DeleteIcon sx={{ marginRight: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <Snackbar
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
