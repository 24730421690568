import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { Button, Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import { ArrowCircleRightOutlined, ArrowRightAlt, Assessment, AssessmentOutlined, BathtubOutlined, BedOutlined, CurrencyExchange, CurrencyExchangeOutlined, DateRange, DateRangeOutlined, DirectionsCarOutlined, Grid4x4, Inventory, InventoryOutlined, LibraryAdd, LibraryAddOutlined, Sell, SellOutlined, SquareFoot, SquareFootOutlined } from '@mui/icons-material';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Bed from '@mui/icons-material/Bed';
import Bathtub from '@mui/icons-material/Bathtub'
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <Grid container spacing={2} sx={{padding: '15px 20px', borderRadius: '5px', background: '#fff', marginBottom: 3}} >
      <Grid display='flex' justifyContent='space-between' size={12}>
        <Grid display='flex' alignItems='left' flexDirection='column' size={8}>
          <Typography sx={{marginBottom: 1}} textAlign={'start'} variant='h6'>
            3 Central Ave, Mylands WA6105
          </Typography>
          <Typography sx={{marginBottom: 1, color:'#5dae87'}} textAlign={'start'} variant='p'>
            3 Central Ave, Mylands WA6105
          </Typography>
          <Grid container spacing={2} display='flex'>
          <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
            <BedOutlined sx={{marginRight: 1}}/> 4 beds
          </Typography>
          <Typography textAlign={'start'} display='flex' alignItems='center' variant='body1'>
            <BathtubOutlined sx={{marginRight: 1}}/> 4 Baths
          </Typography>
          <Typography textAlign={'start'} display='flex' alignItems='center' variant='body1'>
            <DirectionsCarOutlined sx={{marginRight: 1}}/> 4 Parks
          </Typography>
          <Typography textAlign={'start'} display='flex' alignItems='center' variant='body1'>
            <SquareFootOutlined sx={{marginRight: 1}}/> 4234 Sqft
          </Typography>
          </Grid>
        </Grid>
        <Grid display='flex' justifyContent='end' alignItems='center' size={4}>
          <Button style={{background: '#5dae87'}} variant='contained'>Book this property <ArrowRightAlt/></Button>
        
        </Grid>
      
      </Grid>
      <Grid container spacing={3}>
      

        <Grid size={4} sx={{border: '0 !important', boxShadow: 'none !important'}}>
          <Card >
          <CardMedia
            component="img"
            height="400"
            image="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800"
            srcSet="https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x"
            loading="lazy"
            alt="Unsplash image"
            sx={{borderRadius: 2}}
          />
        </Card>
        </Grid>
        <Grid size={8}>
          <Grid display='flex' flexDirection='column' sx={{marginBottom: 4}}>
            <Typography sx={{marginBottom: 2}} textAlign='start' variant='h6'> Snap up this great Investment</Typography>
            <Typography textAlign={'start'} display='flex' alignItems='center' variant='body1'>
             Lorem ipsum dolor sit, amet consectetur adipisicing elit. Temporibus, aliquam nesciunt ab vitae eos praesentium ad animi, perspiciatis similique sapiente error tempore sint rem voluptas hic voluptatibus a obcaecati asperiores, distinctio ducimus provident cum! Consequatur iure earum et non ea nulla corrupti pariatur, hic eos voluptates suscipit quos voluptatem explicabo!
          </Typography>
          </Grid>
          <Grid container spacing={4} sx={{marginBottom: 4}}>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <SellOutlined sx={{marginRight: 1}}/> Median Price
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  $ 4000
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <DateRangeOutlined sx={{marginRight: 1}}/> Weekly Median Report
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  $ 4000
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <CurrencyExchangeOutlined sx={{marginRight: 1}}/> Potential Cashflow
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  $ 20,000
                </Typography>
            </Grid>
          
          </Grid>
          <Grid container spacing={4} sx={{marginBottom: 4}}>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <AssessmentOutlined sx={{marginRight: 1}}/> Potential Gross yield
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  5.2%
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <InventoryOutlined sx={{marginRight: 1}}/> Vacancy rate
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  0.6%
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <LibraryAddOutlined sx={{marginRight: 1}}/> Listed
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  31 Days
                </Typography>
            </Grid>
          
          </Grid>
          <Divider></Divider>
           <Grid container spacing={4} sx={{marginBottom: 2, marginTop: 4}}>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <AssessmentOutlined sx={{marginRight: 1}}/> Potential Gross yield
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  5.2%
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column' >

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <InventoryOutlined sx={{marginRight: 1}}/> Low range
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  0.6%
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <LibraryAddOutlined sx={{marginRight: 1}}/> med range
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  31 Days
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='column'>

                <Typography textAlign={'start'}  display='flex' alignItems='center' variant='body1'>
                <LibraryAddOutlined sx={{marginRight: 1}}/> High range
              </Typography>
                <Typography sx={{marginLeft: 4}} textAlign={'start'} variant='body1'>
                  31 Days
                </Typography>
            </Grid>
          
          </Grid>
          
        </Grid>
      </Grid>
      </Grid>
      <Grid container spacing={2} sx={{background: '#fff', padding: '10px 20px'}}>
        <Grid display='flex' flexDirection='column' size={4} sx={{border: '1px #b9b9b9 solid', borderRadius: 1, padding: '10px'}}>
        <Typography sx={{marginBottom: 1, }} textAlign={'left'} variant='p'>
            Agent Details
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Name: Test agent
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Phone: +25763337019
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Email: test@gmail..com
          </Typography>
          <Button style={{background: '#5dae87'}} variant='contained'> <WifiCalling3OutlinedIcon sx={{marginRight: 1}}/>Contact agent</Button>
        </Grid>
        <Grid size={8} display='flex' flexDirection='column' sx={{border: '1px #b9b9b9 solid', borderRadius: 1, padding: '10px'}}>
          <Typography sx={{marginBottom: 1, }} textAlign={'left'} variant='p'>
            Agent Details
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Name: Test agent
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Phone: +25763337019
          </Typography>
          <Typography sx={{textAlign: 'left'}}>
            Email: test@gmail..com
          </Typography>
          <Button style={{background: '#5dae87'}} variant='contained'> <WifiCalling3OutlinedIcon sx={{marginRight: 1}}/>Book a Tour</Button>
        
        </Grid>

      
      </Grid>
    </Box>
  );
}