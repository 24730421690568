import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent } from '@mui/material';
import Navbar from 'scenes/navbar';
import Sidebar from 'scenes/global/Sidebar';
import LoanReviewTable from 'components/Tables/LoanReviewTable';

const LoanReview = () => {
  const { loanId } = useParams(); // Get loanId from URL parameters
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Use dummy data for the loan details
    const fetchLoanDetails = () => {
      // Dummy data
      const dummyLoans = [
        {
          amountRequested: 1500,
          loanPurpose: 'Rent payment',
          tenantName: 'John Doe',
          email: 'john@gmail.com',
          monthlyIncome: 4500,
          currentRent: 1400,
          interestRate: '5%',
          repaymentSchedule: 'Monthly',
          status: 'Pending',
          creditScore: 720,
        },
        {
          amountRequested: 2000,
          loanPurpose: 'Home repair',
          tenantName: 'Jane Smith',
          email: 'jane@gmail.com',
          monthlyIncome: 5000,
          currentRent: 1500,
          interestRate: '4%',
          repaymentSchedule: 'Monthly',
          status: 'Approved',
          creditScore: 750,
        },
        {
          amountRequested: 1200,
          loanPurpose: 'Medical expenses',
          tenantName: 'Michael Johnson',
          email: 'michael@gmail.com',
          monthlyIncome: 4200,
          currentRent: 1300,
          interestRate: '6%',
          repaymentSchedule: 'Monthly',
          status: 'Pending',
          creditScore: 680,
        },
      ];
      setLoans(dummyLoans);
      setLoading(false);
    };

    fetchLoanDetails();
  }, [loanId]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (loans.length === 0) {
    return <Typography>No loan details found</Typography>;
  }

  return (
    <Box m="20px">
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Card sx={{ width: 500, backgroundColor: '#ffebf2' }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Credit Score
            </Typography>
            <Typography variant="h4">
              {loans[0].creditScore} {/* Display credit score of the first loan */}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}>
        Loan Review
      </Typography>

      <LoanReviewTable loans={loans} />
    </Box>
  );
};

export default LoanReview;
