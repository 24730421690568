import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableFooter,
  TablePagination,
  Box,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useBrokerPayments } from "services/hooks/rentals";
import {Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { baseURL } from "services/API";
import EditBrokerPayments from "components/BrokerPaymentsModals/EditBroker";


const PaymentsTable = ({broker}) => {
  const theme = useTheme();
  const { brokerPayments, isLoadingBrokerPayments, errorBrokerPayments } = useBrokerPayments(broker.id);
  const [selectedBrokerPayment, setSelectedBrokerPayment] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  // dispatch(setBroker(broker));
  const [severity, setSeverity] = React.useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [message, setMessage] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [brokerPaymentDetails, setBrokerPaymentDetails] = useState({
    amount: "",
    description: "",
    date_paid: ""
  })

  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  if (isLoadingBrokerPayments) return <Typography>Loading...</Typography>;
  if (errorBrokerPayments) return <Typography>Error loading payments</Typography>;
  
  const handleMenuClick = (event, brokerPayment) => {
    setAnchorEl(event.currentTarget);
    setSelectedBrokerPayment(brokerPayment);
    
  };
  const handleMenuClose = () => {
    setSelectedBrokerPayment(null);
    setAnchorEl(null);
  };

  const handleEditLandlordPayments = async () => {
    console.log(brokerPaymentDetails)
    try {
      const response = await axios.post(`${baseURL}/admin/broker_payments/details/${selectedBrokerPayment.id}`, brokerPaymentDetails);
      console.log("Payment edited successfully:", response.data);
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment edited successfully");
      setEditDialogOpen(false);
      // Optionally, refresh or update the brokerPayments list to reflect changes
    } catch (error) {
      console.error("Error editing payment:", error);
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error editing payment");
    }
    handleMenuClose();
  };
  

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  const handleEditDialogOpen = () =>{
    setEditDialogOpen(true)
  }
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setBrokerPaymentDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: value,
  //   }));
  // };
   
  const handleInputChange = (event, fieldName) => {
    setBrokerPaymentDetails(brokerPayment =>({
      ...brokerPayment,
      [fieldName]:event.target.value
    })) 

  };

  const handleDeleteBrokerPayment = async (brokerPayment) => {
    console.log(brokerPayment);
    try {
      const response = await axios.delete(`${baseURL}/admin/broker_payments/details/${brokerPayment.id}`);
      console.log(response.data);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment deleted successfully");
    } catch (error) {
      console.error("Error deleting payment:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error deleting payment");
    }
    setDeleteDialogOpen(false);
  };


  return (
    <>
      {brokerPayments.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: "linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)",
                    "& th": {
                      color: "#333333",
                      fontWeight: "bold",
                      fontSize: "16px",
                      backgroundColor: "#FEF0DE",
                    },
                  }}
                >
                  <TableCell>Broker Name</TableCell>
                  <TableCell>Broker Location</TableCell>
                  <TableCell>Landlord Name</TableCell>
                  <TableCell>Date Paid</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? brokerPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : brokerPayments
                ).map((brokerPayment) => (
                  <TableRow
                  key={brokerPayment.id}
                  sx={{
                    "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                  }}
                >
                  <TableCell sx={{ fontSize: "14px" }}>
                    {brokerPayment.related_broker_landlord.related_broker.broker_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    {brokerPayment.related_broker_landlord.related_broker.broker_location}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>
                    {brokerPayment.related_broker_landlord.related_landlord.related_user.first_name}{" "}
                    {brokerPayment.related_broker_landlord.related_landlord.related_user.last_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.date_paid}</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.amount}</TableCell>
                  <TableCell sx={{ fontSize: "14px" }}>{brokerPayment.description}</TableCell>
                  
                  {/* Actions column */}
                  <TableCell sx={{ fontSize: "14px" }}>
                    <IconButton
                      onClick={(event) =>handleMenuClick(event, brokerPayment)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleEditDialogOpen}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDeleteBrokerPayment(brokerPayment)} style={{ color: 'red' }}>
                          Delete
                        </MenuItem>
                      </Menu>
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={6}
                    count={brokerPayments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments available</Typography>
      )}
      <EditBrokerPayments
  open={editDialogOpen}
  title="Edit Broker Payment Details"
  handleClose={handleEditDialogClose}
  handleSubmit={handleEditLandlordPayments}
  handleInputChange={handleInputChange}
  isSubmittingData={false}
  description={selectedBrokerPayment?.description}
  date_paid={selectedBrokerPayment?.date_paid}
  amount={selectedBrokerPayment?.amount}
/>

    </>
  );
};

export default PaymentsTable;
