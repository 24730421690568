import React from "react";
import {Box,Grid,MenuItem,Select,FormControl,InputLabel,TextField,CircularProgress, FormControlLabel, Checkbox} from "@mui/material";
import { months } from "moment";

const AddTenantPaymentForm = ({
  monthOptions,
  isLoadingMonthsAndYears,
  handleChangeAmount,
  handleChangeMonth,
  month,
  handleChangeDatePaid,
  handleChangeNextPaymentDate,
  handleChangePaymentMethod,
  paymentMethod,
  handleInitialPayment,
  initialPayment,
  handleSecurityDeposit,
  hasSecurityDeposit,
  nextPaymentDate
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>

        {/* Row 2 */}
        <Grid item xs={6}>
          <TextField
            label="Amount Paid"
            type="number"
            name="amount"
            onChange={handleChangeAmount}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="related-month-label">Month Paid For</InputLabel>
            {isLoadingMonthsAndYears ? (
              <CircularProgress size={24} sx={{ ml: 2 }} />
            ) : (
              <Select
                labelId="related-month-label"
                name="related_month"
                value={month}
                onChange={handleChangeMonth}
              >
                  {monthOptions.map((value, index ) => {
                  return (
                      <MenuItem key={index} value={value.id}>{value.month} - {value.year}</MenuItem>
                  )
              })}
              </Select>
            )}
          </FormControl>
        </Grid>

        {/* Row 3 */}
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Payment Method</InputLabel>
            <Select
              label="Payment Method"
              name="payment_method"
              value={paymentMethod }
              onChange={handleChangePaymentMethod}
            >
              <MenuItem value={1}>Cash</MenuItem>
              <MenuItem value={2}>Mobile Money</MenuItem>
              <MenuItem value={3}>Momo Pay</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Date Paid"
            type="date"
            name="date_paid"
            onChange={handleChangeDatePaid}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel label="Is this an initial Payment?" control={<Checkbox checked={initialPayment} onChange={handleInitialPayment}/>}  />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel label="Does this initial payment have a security deposit?" control={<Checkbox checked={hasSecurityDeposit} onChange={handleSecurityDeposit}/>}  />
        </Grid>

      </Grid>
    </Box>
  );
};

export default AddTenantPaymentForm;
