import React, { useState } from "react";
import axios from "axios";
import {Box, Button, Typography, Paper, Menu, MenuItem, Stack, Snackbar, Alert, Select, MenuItem as SelectMenuItem, InputLabel, FormControl, useTheme} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import { baseURL } from "services/API";
import { useBrokerLandlords } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import BrokersLandlordsTable from "components/Tables/BrokersLandlordsTable";
import FormDialog from "components/modal";
import { BrokerLandlordsForm } from "components/Forms/BrokerLandlordForm";

export default function BrokerDetails() {

  const broker = useSelector((state) => state.broker)
  const {
    brokerLandlords, loadingBrokerLandlords, errorBrokerLandlords
  } = useBrokerLandlords(broker.id);

  const [brokers, setBrokers] = useState([]);
  const [allLandlords, setAllLandlords] = useState([]); // New state for all landlords
  const [anchorEl, setAnchorEl] = useState(null);
  const [newLandlord, setNewLandlord] = useState(null);
  const [landlords, setLandlords] = useState([]);
	const [isLoadingLandlords, setLoadingLandlords] = useState(true);
  const [isSubmittingData, setSubmittingData] = useState(false)
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false)
  
  const [snack, setSnack] = useState({open: false, message: "", severity: "info",});

  const theme = useTheme();


  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setNewLandlord(event.target.value)
  };

  const handleOpenAttachLandlord = async () => {
    setOpen(true)
    try {
      const response = await axios.get(`${baseURL}/landlords/admin/landlords`);
      setLandlords(response.data.data);
      setLoadingLandlords(false);
    } catch (e) {
      setError(true);
      setLoadingLandlords(false);
    }
  }

  const handleAddLandlord = async () => {
    setSubmittingData(true)
    try {
      await axios.post(
        `${baseURL}/landlords/admin/brokers/landlords`,
        {
          "related_broker": broker.id,
          "related_landlord": newLandlord
        }
      );

      setSubmittingData(false)
      setOpen(false);
      setSnack({
        open: true,
        message: "Landlord added successfully",
        severity: "success",
      });

    } catch (error) {
      setSnack({
        open: true,
        message: error.response?.data?.message || "An error occurred",
        severity: "error",
      });
      setSubmittingData(false)
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setNewLandlord(null);
  };

  const handleEditLandlord = () => {
    console.log("Editing landlord:", newLandlord);
    handleMenuClose();
  };

  const handleDeleteLandlord = async () => {
    try {
      await axios.delete(
        `${baseURL}/landlords/${newLandlord}`
      );
      const response = await axios.get(
        `${baseURL}/landlords/admin/brokers/landlords`,
        // { params: { brokerId } }
      );
      // setLandlords(response.data.data);
    } catch (error) {
      console.error("Error deleting landlord:", error);
    }
    handleMenuClose();
  };

  const handleSendMessage = () => {
    handleMenuClose();
  };

  const handleCloseSnack = () => {
    setSnack({ ...snack, open: false });
  };

  return (
      <Box m="20px">
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }}
          >
            Broker Details ({broker.broker_name})
          </Typography>
          <Button variant="contained" color="primary" onClick={handleOpenAttachLandlord}>             
          Attach New Landlord
          </Button>
        </Stack>
        <Paper sx={{ marginTop: "1rem", overflow: "hidden" }}>

          <BrokersLandlordsTable brokerLandlords={brokerLandlords} loadingBrokerLandlords={loadingBrokerLandlords} errorBrokerLandlords={errorBrokerLandlords} handleMenuOpen={handleMenuOpen}/>
        </Paper>

        <FormDialog 
          open={open} 
          title={"Add Landlord"} 
          text={"Attach a landlord to a Broker"}
          content={
            <BrokerLandlordsForm newLandlord={newLandlord} handleChange={handleChange} landlords={landlords} isLoadingLandlords={isLoadingLandlords}/>
          }
          handleClose={handleClose}
          handleSubmit={handleAddLandlord}
          isSubmittingData={isSubmittingData}
        />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleEditLandlord}>
            <EditIcon sx={{ marginRight: 1 }} />
            Edit
          </MenuItem>
          <MenuItem onClick={handleSendMessage}>
            <MailIcon sx={{ marginRight: 1 }} />
            Send Message
          </MenuItem>
          <MenuItem onClick={handleDeleteLandlord}>
            <DeleteIcon sx={{ marginRight: 1 }} />
            Delete
          </MenuItem>
        </Menu>
        <Snackbar
          open={snack.open}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snack.severity}
            sx={{ width: "100%" }}
          >
            {snack.message}
          </Alert>
        </Snackbar>
      </Box>
  );
}
