import React, { useState } from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, Typography, Box, IconButton, Snackbar, Alert} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useLandlordPayments } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import axios from "axios";
import { baseURL } from "services/API";
import EditLandlorPayments from "components/LandlordPaymentsModal/EditLandlordPayments";

const LandlordDetailsTable = () => {
  const landlord = useSelector((state) => state.landloard); 
  const { landlordPayments, isLoadingLandlordPayments, errorLandlordPayments } = useLandlordPayments(landlord.id);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [selectedLandlordPayment, setSelectedLandlordPayment] = React.useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [landlordPaymentDetails, setLandlordPaymentDetails] = useState({

  })
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = openSnack;

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  if (isLoadingLandlordPayments) {
    return <Typography>Loading...</Typography>;
  }

  if (errorLandlordPayments) {
    return <Typography>Error loading payments.</Typography>;
  }

  const handleMenuClick = (event, landlordPayment) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlordPayment(landlordPayment);
    
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleMenuClose = () => {
    setSelectedLandlordPayment(null);
    setAnchorEl(null);
  };
  const handleEditDialogOpen = () =>{
    setEditDialogOpen(true)
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedLandlordPayment((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
  };
  

  const handleEditLandlordPayments = async () => {
    try {
      const response = await axios.post(`${baseURL}/admin/landlord_payments/details/${selectedLandlordPayment.id}`, landlordPaymentDetails );
      console.log("Payment edited successfully:", response.data);
  
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment edited successfully");
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error editing payment:", error);
  
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error editing payment");
    }
    handleMenuClose();
  };
  

  const handleDeleteLandlordPayment = async (landlordPayment) => {
    console.log(landlordPayment);
    try {
      const response = await axios.delete(`${baseURL}/admin/landlord_payments/details/${landlordPayment.id}`);
      console.log(response.data);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment deleted successfully");
    } catch (error) {
      console.error("Error deleting payment:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error deleting payment");
    }
    setDeleteDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnack({ ...openSnack, open: false });
  };

  return (
    <>
      {landlordPayments.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "16px"}}>Landlord Name</TableCell>
                  <TableCell sx={{ fontSize: "16px"}}>Business Name</TableCell>
                  <TableCell sx={{ fontSize: "16px"}}>Rental Name</TableCell>
                  <TableCell sx={{ fontSize: "16px"}}>Month Paid</TableCell>
                  <TableCell sx={{ fontSize: "16px"}}>Date Paid</TableCell>
                  <TableCell align="right" sx={{ fontSize: "16px" }}>Amount</TableCell>
                  <TableCell align="right" sx={{ fontSize: "16px" }}>Added By</TableCell>
                  <TableCell align="right" sx={{ fontSize: "16px"}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? landlordPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : landlordPayments
                ).map((landlordPayment) => (
                  <TableRow
                    key={landlordPayment.id}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_landlord_rental.related_landlord.related_user.first_name}{" "}
                      {landlordPayment.related_landlord_rental.related_landlord.related_user.last_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_landlord_rental.related_landlord.business_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_landlord_rental.rental_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.related_month.month} {landlordPayment.related_month.year}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.date_paid}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.amount}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: "16px", padding: "16px" }}>
                      {landlordPayment.added_by.first_name} {landlordPayment.added_by.last_name}
                    </TableCell>
                    <TableCell align="right" sx={{ padding: "16px" }}>
                      <IconButton onClick={(event) => handleMenuClick(event, landlordPayment)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleEditDialogOpen}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDeleteLandlordPayment(landlordPayment)} style={{ color: 'red' }}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={landlordPayments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments found for the landlord.</Typography>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {message}
        </Alert>
      </Snackbar>

      <EditLandlorPayments
  open={editDialogOpen}
  title="Edit Landlord Payment Details"
  handleClose={handleEditDialogClose}
  handleSubmit={handleEditLandlordPayments}
  handleInputChange={handleInputChange}
  isSubmittingData={false}
  landlord_name={`${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.first_name} ${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.last_name}`}
  description={selectedLandlordPayment?.description}
  business_name={selectedLandlordPayment?.related_landlord_rental.related_landlord.business_name}
  rental_name={selectedLandlordPayment?.related_landlord_rental.rental_name}
  date_paid={selectedLandlordPayment?.date_paid || ""}
  month_year={`${selectedLandlordPayment?.related_month?.month || ""} ${selectedLandlordPayment?.related_month?.year || ""}`}
  amount={selectedLandlordPayment?.amount || ""}
  paid_by={`${selectedLandlordPayment?.added_by.first_name} ${selectedLandlordPayment?.added_by.last_name}`}
/>

    </>
  );
};

export default LandlordDetailsTable;
