import React, { useState } from "react";
import {Table, TableBody,TableContainer,TableHead,TableRow,Paper,TableFooter,TablePagination,IconButton,Box,} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/Tables/Styles/TableAndCellStyles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material";

export default function RegisteredPropertiesTable({ registeredProperties, isLoadingRegisteredProperties, errorRegisteredProperties}) {
  console.log(registeredProperties)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(
        event,
        Math.max(0, Math.ceil(count / rowsPerPage) - 1)
      );
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

 

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> Landlord Name</StyledTableCell>
            <StyledTableCell>Contact</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Location</StyledTableCell>
            <StyledTableCell>Number of Properties</StyledTableCell>
            <StyledTableCell>Number of Units</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {Array.isArray(registeredProperties) && registeredProperties.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell colSpan={7}>No data available</StyledTableCell>
            </StyledTableRow>
        ): (
          <>
          {registeredProperties.map((registeredProperty) => (
              <StyledTableRow key={registeredProperty.id}>
                <StyledTableCell>{registeredProperty.landlord_name}</StyledTableCell>
                <StyledTableCell>{registeredProperty.contact}</StyledTableCell>
                <StyledTableCell>{registeredProperty.email}</StyledTableCell>
                <StyledTableCell>{registeredProperty.location}</StyledTableCell>
                <StyledTableCell>{registeredProperty.number_of_properties}</StyledTableCell>
                <StyledTableCell>{registeredProperty.number_of_units}</StyledTableCell>
              
              </StyledTableRow>
            ))}
          </>
        )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={6}
              count={registeredProperties.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
