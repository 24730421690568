import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Box,
  MenuItem,
  Menu,
  TableFooter,
  TablePagination,
  useTheme
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom";

const LoanRequestsTable = ({ loanRequests, handleReviewLoan, isLoading, error }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLoan, setSelectedLoan] = React.useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClick = (event, loan) => {
    setAnchorEl(event.currentTarget);
    setSelectedLoan(loan);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (action) => {
    handleMenuClose();
    if (action === "View") {
      navigate(`/loan-review`);
    } else if (action === "Edit") {
      navigate(`/loans/edit/${selectedLoan.id}`);
    } else if (action === "Delete") {
      // Add delete functionality
    }
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const theme = useTheme();

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                background: "linear-gradient(45deg, #FFC0CB 30%, #FFD700 90%)",
                "& th": {
                  color: "#333333",
                  fontWeight: "bold",
                  fontSize: "16px",
                  backgroundColor: "#FEF0DE",
                },
              }}
            >
              <TableCell sx={{ fontSize: "18px" }}>Tenant Contact</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Tenant Name</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Loan Amount</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Date Submitted</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Status</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6}>Loading...</TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6}>Error fetching data</TableCell>
              </TableRow>
            ) : (
              loanRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan) => (
                <TableRow
                  key={loan.id}
                  sx={{
                    "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                  }}
                >
                  <TableCell sx={{ fontSize: '16px' }}>{loan.Contact}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{loan.tenantName}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{`$${loan.amount.toLocaleString()}`}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{loan.dateSubmitted}</TableCell>
                  <TableCell sx={{ fontSize: '16px' }}>{loan.status}</TableCell>
                  <TableCell>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, loan)}
                    >
                      <MoreVertIcon />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleMenuAction("View")}>
                        <VisibilityIcon color="action" sx={{ mr: 1 }} />
                        View
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Edit")}>
                        <EditIcon color="action" sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuAction("Delete")}>
                        <DeleteIcon color="action" sx={{ mr: 1 }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={6}
                count={loanRequests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                   'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LoanRequestsTable;
