import  React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

export default function EditLandlorPayments({open,title,text,handleClose,handleSubmit,isSubmittingData,description,date_paid,amount,
  handleInputChange,business_name, landlord_name,rental_name, month_year, paid_by}) {

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 15, marginBottom: 2 }}>
            {text}
          </DialogContentText>


          {/* Form Fields */}
          <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box display="flex" gap={2}>
              <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="landlord_name" sx={{ fontSize: 14 }}>Landlord Name</InputLabel>
              <TextField
                  id="landlord_name"
                  variant="outlined"
                  fullWidth
                  defaultValue={landlord_name}
                  onChange={(e) => handleInputChange(e, 'landlord_name')}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="business_name" sx={{ fontSize: 14 }}>Business Name</InputLabel>
              <TextField
                  id="business_name"
                  variant="outlined"
                  fullWidth
                  defaultValue={business_name}
                  onChange={(e) => handleInputChange(e, 'business_name')}
                />
              </Box>
            </Box>
            <Box display="flex" gap={2}>
              <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="rental_name" sx={{ fontSize: 14 }}>Rental Name</InputLabel>
                <TextField
                  id="rental_name"
                  variant="outlined"
                  fullWidth
                  defaultValue={rental_name}
                  onChange={(e) => handleInputChange(e, 'rental_name')}
                /> 
                </Box>
                <Box sx={{ flex: 1 }}>
  <InputLabel htmlFor="month_year" sx={{ fontSize: 14 }}>Month and Year</InputLabel>
  <TextField
    id="month_year"
    variant="outlined"
    fullWidth
    defaultValue={month_year} // Use optional chaining
    onChange={(e) => handleInputChange(e, 'month_year')}
  />
</Box>


            </Box>
            <Box display="flex" gap={2}>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="description" sx={{ fontSize: 14 }}>New Description</InputLabel>
                <TextField
                  id="description"
                  variant="outlined"
                  fullWidth
                  defaultValue={description}
                  onChange={(e) => handleInputChange(e, 'description')}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel htmlFor="date_paid" sx={{ fontSize: 14 }}>Date Paid</InputLabel>
                <TextField
                  id="date_paid"
                  variant="outlined"
                  fullWidth
                  defaultValue={date_paid}
                  onChange={(e) => handleInputChange(e, 'date_paid')}
                />
              </Box>
            </Box>
            <Box display="flex" gap={2}>
            <Box sx = {{ flex: 1 }}>
            <InputLabel htmlFor="paid_by" sx={{ fontSize: 14 }}>Paid By</InputLabel>
              <TextField
                id="paid_by"
                variant="outlined"
                fullWidth
                defaultValue={paid_by}
                onChange={(e) => handleInputChange(e, 'paid_by')}
              /> 
            </Box>
            <Box sx={{ flex: 1 }}>
              <InputLabel htmlFor="amount" sx={{ fontSize: 14 }}>Amount</InputLabel>
              <TextField
                id="amount"
                variant="outlined"
                fullWidth
                defaultValue={amount}
                onChange={(e) => handleInputChange(e, 'amount')}
              />
            </Box>
            </Box>
           
          </Box>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button variant="outlined" onClick={handleClose} sx={{ color: "red", fontSize: 14 }}>
            Cancel
          </Button>
          {isSubmittingData ? (
            <LoadingButton
              variant="filled"
              loading
              startIcon={<SaveIcon />}
              loadingPosition="start"
              sx={{ color: "#188c0b", fontSize: 14 }}
            >
              Editing Data ...
            </LoadingButton>
          ) : (
            <Button variant="outlined" onClick={handleSubmit} sx={{ color: "#188c0b", fontSize: 14 }}>
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
