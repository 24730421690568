import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useBrokers } from "services/hooks/rentals";
import { setBroker } from "state";
import FormDialog from "components/modal";
import BrokersTable from "components/Tables/BrokersTable";
import BrokerForm from "components/Forms/BrokersForm";
import { useState } from "react";

export default function Brokers() {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const itemsPerPage = 10;

  const [newBroker, setNewBroker] = useState({
    broker_name: "",
    broker_location: "",
    broker_contact: "",
    broker_NIN: "", // Added the NIN field here
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const { brokers, isLoadingBrokers, error, refetch } = useBrokers();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnack, setOpenSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const handleMenuClick = (event, broker) => {
    dispatch(setBroker(broker));
    setAnchorEl(event.currentTarget);
    setSelectedBroker(broker);
  };

  const handleMenuAction = (action) => {
    if (action === "Payments") {
      navigate(`/broker-payments`);
    }
    if (action === "View") {
      navigate(`/broker-details`);
    }
    handleMenuClose(action);
  };

  const handleMenuClose = (action) => {
    setAnchorEl(null);
    if (action === "Edit") {
      setEditDialogOpen(true);
    } else if (action === "Send Message") {
      setMessageDialogOpen(true);
    } else if (action === "Delete") {
      setDeleteDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setMessageDialogOpen(false);
    setDeleteDialogOpen(false);
    setOpen(false);
    setNewBroker({
      broker_name: "",
      broker_location: "",
      broker_contact: "",
      broker_NIN: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewBroker((prevBroker) => ({ ...prevBroker, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};
  
    // Validate broker name
    if (!newBroker.broker_name.trim()) errors.broker_name = "Broker Name is required.";
    
    // Validate broker Location
    if (!newBroker.broker_location.trim()) errors.broker_location = "Broker location is required.";
    
    // Validate broker NIN
    if (!newBroker.broker_NIN.trim()) errors.broker_NIN = "Broker NIN is required.";
    
    // Validate phone number format
    if (!newBroker.broker_contact) {
      errors.broker_contact = "Contact is required.";
    } else if (!/^(0[0-9]{9}|(\+\d{1,3}[0-9]{9}))$/.test(newBroker.broker_contact)) {
      errors.broker_contact = "Contact valid format is: 0751234567 or +345783334310.";
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  

  const handleSubmitBroker = async () => {
    if (!validateForm()) {
      setOpenSnack({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Please fill all fields",
        severity: "error",
      });
      return;
    }
    
    setIsSubmittingData(true);
    try {
      const response = await axios.post(`${baseURL}/landlords/admin/brokers`, newBroker);
      setSuccessMessage("Broker added successfully!");
      refetch();
      setOpen(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.error);
    } finally {
      setIsSubmittingData(false);
      setNewBroker({
        broker_name: "",
        broker_location: "",
        broker_contact: "",
        broker_NIN: "",
      });
    }
  };

  const handleClose = () => {
    handleDialogClose();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  if (errorMessage) {
    setTimeout(() => {
      setErrorMessage(""); 
    }, 5000);
  }
  if (successMessage) {
    setTimeout(() => {
      setSuccessMessage(""); 
    }, 5000);
  }

  return (
    <Box m="20px">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography fontWeight="bold" fontSize="25px">
              Brokers
            </Typography>
            <Button variant="contained" onClick={() => setOpen(true)} sx={{ marginLeft: 'auto' }}>
              Add Broker
            </Button>
          </Box>
  
          {/* Space between the title and messages */}
          <Box sx={{ mb: 2 }}>
            {successMessage && <p style={{ color: "green", fontSize: "14px" }}>{successMessage}</p>}
            {errorMessage && <p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>}
          </Box>
  
          <FormDialog
            open={open}
            title={"Add Broker"}
            text={"Assign a new Broker"}
            content={
              <BrokerForm
                open={open}
                handleDialogClose={handleDialogClose}
                handleChange={handleChange}
                newBroker={newBroker}
                errors={errors}
              />
            }
            handleSubmit={handleSubmitBroker}
            handleClose={handleClose}
            isSubmittingData={isSubmittingData}
          />
  
          <BrokersTable
            brokers={brokers}
            isLoadingBrokers={isLoadingBrokers}
            error={error}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handlePageChange={handlePageChange}
            newSelectedBroker={selectedBroker}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            handleMenuAction={handleMenuAction}
            anchorEl={anchorEl}
          />
        </Grid>
      </Grid>
    </Box>
  );
  
}
